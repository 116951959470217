import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Box from "./box"
import Caption from "./caption"
import { GatsbyImage } from "gatsby-plugin-image"
import Inline from "./inline"
import Text from "./text"
import Stack from "./stack"

const Image = (props) => {
  const { caption, attribution, ...imageProps } = props

  return (
    <Box overflow="hidden" position="relative">
      <Stack space={3}>
        <Box position="relative">
          <GatsbyImage {...imageProps} />
          {attribution && (
            <Box position="absolute" bottom={0} left={0} right={0} p={3}>
              <Text size="10px" role="presentation" color="white" as="div" sans>
                <Inline space={1} alignX="right">
                  <span>Bild:</span>
                  <span>{attribution}</span>
                </Inline>
              </Text>
            </Box>
          )}
        </Box>
        {caption && <Caption align="center">{caption}</Caption>}
      </Stack>
    </Box>
  )
}

export default Image

Image.propTypes = {
  attribution: PropTypes.string,
  caption: PropTypes.string,
}

export const largeImage = graphql`
  fragment largeImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`

export const pinnedPostImage = graphql`
  fragment pinnedPostImage on File {
    childImageSharp {
      gatsbyImageData(width: 400, height: 200, layout: CONSTRAINED)
    }
  }
`

export const faktenPosterImage = graphql`
  fragment faktenPosterImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`

export const hyperCardImage = graphql`
  fragment hyperCardImage on File {
    childImageSharp {
      gatsbyImageData(width: 700, height: 700, layout: CONSTRAINED)
    }
  }
`

export const avatarImage = graphql`
  fragment avatarImage on File {
    childImageSharp {
      gatsbyImageData(width: 100, height: 100, layout: CONSTRAINED)
    }
  }
`

export const avatarImageMedium = graphql`
  fragment avatarImageMedium on File {
    childImageSharp {
      gatsbyImageData(width: 300, height: 300, layout: CONSTRAINED)
    }
  }
`

export const avatarImageLarge = graphql`
  fragment avatarImageLarge on File {
    childImageSharp {
      gatsbyImageData(width: 640, height: 640, layout: CONSTRAINED)
    }
  }
`

export const mediumImage = graphql`
  fragment mediumImage on File {
    childImageSharp {
      gatsbyImageData(width: 620, layout: CONSTRAINED)
    }
  }
`

export const smallImage = graphql`
  fragment smallImage on File {
    childImageSharp {
      gatsbyImageData(width: 360, layout: CONSTRAINED)
    }
  }
`
