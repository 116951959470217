import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Constrain from "../../../components/constrain"
import Image from "../../../components/image"
import QuotePrimary from "../../../components/quote-primary"
import Stack from "../../../components/stack"

const StammbuchHoelderlin = (props) => {
  const data = useStaticQuery(graphql`
    query StammbuchHoelderlinQuery {
      hoelderlin: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/02/stammbuch-hoelderlin.png"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Image
        image={data.hoelderlin.childImageSharp.gatsbyImageData}
        alt="Eine Seite aus Hegels Stammbuch"
        caption="Manuskript aus der Bibliothek der Eberhard Karls Universität Tübingen."
      />
      <Constrain align="right">
        <QuotePrimary author="Hölderlins Eintrag in Hegels Stammbuch">
          <span>»Goethe.</span>
          <span>Lust und Liebe sind</span>
          <span>die Fittige zu großen Taten.</span>
          <span>Schriebs zum Andenken</span>
          <span>Dein Freund</span>
          <span>M[agister] Hölderlin«.</span>
        </QuotePrimary>
      </Constrain>
    </Stack>
  )
}

export default StammbuchHoelderlin
