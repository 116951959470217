import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Finsterwelt = () => {
  const data = useStaticQuery(graphql`
    query Finsterwelt {
      depner: file(
        relativePath: { eq: "veranstaltungen/depner.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
      kantwuerfel: file(
        relativePath: { eq: "veranstaltungen/kantwuerfel.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        In der Kinderbuchreihe 'Finsterwelt' von Katharina Herzog erwarten Jungs und Mädchen von 8 bis 12 Jahren eine turbulente Geschichte 
        voller Humor und Märchenmagie. Leonie, die Hauptfigur, stammt vom Froschkönig ab, und das ist kein Spaß, 
        wenn man zusammen mit so coolen Kids wie den Nachfahr*innen von Schneewittchen, Dornröschen und Rapunzel auf eine Schule geht. 
        Und dann ist da noch der neue Schüler Tristan, von dem niemand weiß, aus welcher Märchenfamilie er abstammt. 
        Ausgerechnet mit ihm muss Leonie die Märchenkammer auf Schloss Rosenfels abstauben und stößt dabei auf ein Buch, 
        dass die ganze Märchengeschichte für immer verändern könnte.
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
            Aus Platzgründen ist eine Anmeldung erforderlich:{" "}
            <ExternalLink href="https://tuebingen.ferienprogramm-online.de/">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
        </Paragraph>
        <Paragraph>
            In Kooperation mit der Stadtbücherei Tübingen, der Familienbeauftragen der Stadt Tübingen und der Grundschule Innenstadt.
        </Paragraph>
      </Stack>
    </Stack>
  )
}

export default Finsterwelt