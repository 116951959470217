import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Blockquote from "../../../components/blockquote"
import Stack from "../../../components/stack"
import QuoteBox from "../../../components/quote-box"
import QuotePrimary from "../../../components/quote-primary"
import Poem from "../../../components/poem"
import VideoPlayer from "../../../components/video-player"
import Breakout from "../../../components/breakout"
import Grid from "../../../components/grid"
import Container from "../../../components/container"
import Box from "../../../components/box"
import Caption from "../../../components/caption"

const Gedichtwerk = () => {
  const data = useStaticQuery(graphql`
    query RaumFuegungenGedichtwerkQuery {
      pindar: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/pythische-ode.jpg"
        }
      ) {
        ...largeImage
      }
      poster01: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/poster-pindar-01.jpg"
        }
      ) {
        ...largeImage
      }
      poster02: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/poster-pindar-02.jpg"
        }
      ) {
        ...largeImage
      }
      poster03: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/poster-pindar-03.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack space={24}>
      <Stack>
        <Constrain>
          <Stack>
            <Heading as="h2" level={2}>
              Hölderlins Gedichtwerk
            </Heading>
            <Blockquote>
              <Poem>
                <span>»Ists schwacher Schwung nach Pindars Flug? ists</span>
                <span>Kämpfendes Streben nach Klopstoksgröße?«</span>
              </Poem>
            </Blockquote>
            <Paragraph>
              Wenn sich Hölderlin als 17-jähriger Klosterschüler in seiner
              Maulbronner Ode ›Mein Vorsaz‹ den zeitgenössischen Klopstock und
              den antiken Pindar zu Vorbildern nimmt, dann formuliert er bereits
              am Beginn seiner Laufbahn ein ungemein ehrgeiziges Programm:
              experimentell aus der Tradition zu schöpfen und zugleich modernste
              Formen zu wagen.
            </Paragraph>
            <Paragraph>
              Klopstock hatte die deutsche Lyrik aus den Zwängen monotoner
              barocker Dichtungsnormen befreit und sogar eigene freie Rhythmen
              gewagt. Wenn Hölderlin zudem fasziniert auf »Pindars Flug« setzt,
              dann kalkuliert er die Gefahr des Scheiterns mit ein. Schon in der
              Antike hatte man Pindars Nacheiferer mit dem abstürzenden Ikarus
              verglichen.
            </Paragraph>
            <Paragraph>
              Mit Worten wie: »Ich erreich’ ihn nie, den / Weltenumeilenden Flug
              der Großen«, inszeniert sich ein ehrgeiziger Jungdichter
              schwankend zwischen Anspruch und Verzagtheit – gleichwohl schon
              hier im ambitionierten griechischen Versmaß der alkäischen
              Strophe.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain align="right">
          <QuotePrimary author="Friedrich Hölderlin: Mein Vorsaz">
            <span>O Freunde! Freunde! die ihr so treu mich liebt!</span>
            <span>Was trübet meine einsame Blike so?</span>
            <span>Was zwingt mein armes Herz in diese</span>
            <span>Wolkenumnachtete Todtenstille?.</span>
            <span>
              <br />
            </span>
            <span>Ich fliehe euren zärtlichen Händedruk,</span>
            <span>Den seelenvollen, seligen Bruderkuß.</span>
            <span>O zürnt mir nicht, daß ich ihn fliehe!</span>
            <span>Schaut mir in’s Innerste! Prüft und richtet! -</span>
            <span>
              <br />
            </span>
            <span>Ists heißer Durst nach Männervollkommenheit?</span>
            <span>Ists leises Geizen um Hekatombenlohn?</span>
            <span>Ists schwacher Schwung nach Pindars Flug? ists</span>
            <span>Kämpfendes Streben nach Klopstoksgröße?</span>
          </QuotePrimary>
        </Constrain>
      </Stack>

      <Constrain>
        <Stack>
          <Heading as="h3" level={4}>
            Schwacher Schwung nach Pindars Flug
          </Heading>
          <Paragraph>
            Von der Maulbronner Klosterschule bis zum Einzug in den Tübinger
            Turm bezog sich Hölderlin in seinem Schaffen immer wieder auf
            Pindar. Dabei hatte die deutsche Rezeption des Griechen, der in der
            Antike als Höchster unter den Lyrikern galt, erst um Hölderlins
            Geburt herum neu eingesetzt.
          </Paragraph>
          <Paragraph>
            Mit dem schwierigen Pindar, so war man sich damals einig, legen sich
            nur Genies an. Er galt ebenso als mustergültiger Klassiker wie auch
            als Meister der Regellosigkeit und der hermetisch abgeschlossenen
            Verse. Für Hölderlin, der auf der Suche nach neuen poetischen Formen
            war, wurde dieser Dichter des Eigensinns zum Gewährsmann einer
            Tradition des Traditionsbruchs.
          </Paragraph>
          <Breakout>
            <Box px={[6, 6, 12]}>
              <Container>
                <Stack>
                  <Container maxWidth="lg">
                    <Heading as="h4" level={5} align="center">
                      Prof. Dr. Wilfried Stroh über Hölderlins Beschäftigung mit
                      Pindar
                    </Heading>
                  </Container>
                  <Grid columns={[1, 2, 2, 3]} alignX="center">
                    <Stack space={6}>
                      <VideoPlayer src="pindar-01" poster={data.poster01} />
                      <Caption>
                        Was hat die deutschen Dichter an Pindar fasziniert?
                      </Caption>
                    </Stack>
                    <Stack space={6}>
                      <VideoPlayer src="pindar-02" poster={data.poster02} />
                      <Caption>
                        Wie wurden Pindars Gedichte in der Antike rezipiert?
                      </Caption>
                    </Stack>
                    <Stack space={6}>
                      <VideoPlayer src="pindar-03" poster={data.poster03} />
                      <Caption>Was hat Hölderlin in Pindar gesehen?</Caption>
                    </Stack>
                  </Grid>
                </Stack>
              </Container>
            </Box>
          </Breakout>
        </Stack>
      </Constrain>

      <Stack>
        <Constrain>
          <Stack>
            <Heading as="h3" level={4}>
              Hölderlin zackert am Pindar
            </Heading>
            <Image
              image={data.pindar.childImageSharp.gatsbyImageData}
              alt="Ein aufgeschlagenes Buch mit Hölderlins Übersetzung der ersten pythischen Ode"
              caption="Oktavbuch mit Hölderlins Pindar-Übertragungen aus der Württembergischen Landesbibliothek Stuttgart"
              attribution="David Franck"
            />
            <Paragraph>
              Das Schreibbuch, in das Hölderlin zunächst einen der
              ›Empedokles‹-Entwürfe eingetragen hatte, nutzte er 1800 um und
              übersetzte dort 2000 Verse, in denen Pindar die Sieger der
              panhellenischen Spiele in Olympia und Delphi besungen hatte.
            </Paragraph>
            <Paragraph>
              Am Inhalt dieser 17 Oden interessierte ihn aber allenfalls das Lob
              des Dichters und der Dichtkunst. Ihm ging es um die Form: Er
              übertrug Wort für Wort ins Deutsche, hielt jedoch strikt am
              fremden griechischen Satzbau fest.
            </Paragraph>
            <Paragraph>
              Das war nicht völlig neu, doch Hölderlin hat dieses Vorgehen
              radikalisiert und sich von allen Dichtern am intensivsten auf
              Pindar eingelassen. Goethe hat sich nur an einer olympischen Ode
              probiert; Schiller an keiner. Hölderlin, der das Übersetzen schon
              früh »eine heilsame Gymnastik für die Sprache« nannte, lernte an
              Pindar so ein poetisches Prinzip, das seine Dichtung nun prägen
              wird und weit in die Moderne weist – die harte Fügung: jene
              widerspenstigen Satzkonstruktionen, in denen die Wörter jäh
              aufeinanderprallen.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain align="left">
          <QuoteBox author="Johann Isaak von Gerning an Karl Ludwig von Knebel, Bad Homburg, 11. Juli 1805">
            Hölderlin, der immer halb verrückt ist, zackert auch am Pindar.
          </QuoteBox>
        </Constrain>
      </Stack>
    </Stack>
  )
}

export default Gedichtwerk
