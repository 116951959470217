/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { RootLayout } from "./src/layouts/root"

// wrap each page into custom root element
export const wrapRootElement = ({ element }) => {
  return <RootLayout>{element}</RootLayout>
}
