import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Poesiekinder = () => {
  const data = useStaticQuery(graphql`
    query Poesiekinder {
        liland: file(
            relativePath: { eq: "veranstaltungen/liland-bawue.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(width: 268, height: 300, layout: CONSTRAINED)
            }
        }
        poesie: file(
            relativePath: { eq: "veranstaltungen/poesiekinder.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(width: 268, height: 360, layout: CONSTRAINED)
            }
        }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Im Turm sitzt Friedrich, der sieht aus dem Fenster und sieht gern den Himmel fern.<br></br>
        Der ändert sich fast immer. Das Leben ist verschieden in den verschiedenen Zeiten.<br></br>
        Wohin bist du schon gereist?<br></br>
        Siehst du die Felsenpinguine in den Dünenfeldern?<br></br> 
        Die Winterblumen, die in breiten Städten glänzen?<br></br>
        Wohin geht der Sommer eigentlich, wenn er uns verlässt? 
        Friedrich sieht aus dem Fenster und wir schreiben, wir malen, wir zeichnen.<br></br>
        Wir drucken einen eigenen Kalender. 
      </Paragraph>
      <Paragraph>
        Mit Anett Frey (Kunst), Klaus Oehler (Poesie) & Nina Lenz (Poesie)
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
            Aus Platzgründen ist eine Anmeldung erforderlich:{" "}
            <ExternalLink href="https://tuebingen.ferienprogramm-online.de/">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
        </Paragraph>
      </Stack>
        <Paragraph>
            <br></br> 
            In Kooperation mit der Lyrikhandlung am Hölderlinturm, der Stadtbücherei Tübingen und dem POEDU.
        </Paragraph>
        <Paragraph> 
            Gefördert aus Landesmitteln durch die{" "}
            <ExternalLink href="https://www.dla-marbach.de/ueber-uns/mitarbeiterinnen-und-mitarbeiter/arbeitsstelle-f-literarische-museen-archive-u-gedenkstaetten-in-bawue/">
                <span style={{color: '#F7A61B'}}> Arbeitsstelle für literarische Museen, Archive und Gedenkstätten in Baden-Württemberg </span> 
            </ExternalLink>
            (Deutsches Literaturarchiv Marbach).
        </Paragraph>
        <Grid columns={2} space={"px"}>
            <Image
                image={data.liland.childImageSharp.gatsbyImageData}
                alt="Logo der Arbeitsstelle für literarische Museen, Archive und Gedenkstätten in Baden-Württemberg"
            />
        </Grid>
        <Grid columns={2} space={"px"}>
            <Image
                image={data.poesie.childImageSharp.gatsbyImageData}
                alt="Titelgrafik Poesiekinder"
            />
        </Grid>
    </Stack>
  )
}

export default Poesiekinder