import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Container from "../../../components/container"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

const Albinus = () => {
  const data = useStaticQuery(graphql`
    query {
      albinus: file(
        relativePath: { eq: "aus-dem-archiv-geholt/07/albinus.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Container maxWidth="lg">
      <Box bg="black">
        <Box pr={[12, 24]}>
          <Image
            image={data.albinus.childImageSharp.gatsbyImageData}
            attribution="Heike Trunz"
            alt="Porträt von Hans-Joachim Albinus"
          />
        </Box>
        <Box p={[6, 12]}>
          <Stack space={6}>
            <Heading level={4} color="white">
              Hans-Joachim Albinus
            </Heading>
            <Text size={[2, 3]} color="white">
              Hans-Joachim Albinus (*1952) studierte Mathematik und Geographie
              an der Ruhr-Universität Bochum sowie Informatik an der
              Fernuniversität Hagen. Nach verschiedenen Stationen in der
              Wissenschafts- und Innenverwaltung in Nordrhein-Westfalen, Hessen
              und Baden-Württemberg war er bis 2020 Ministerialrat im
              Innenministerium Baden-Württemberg. Er wohnt in Leonberg und
              beschäftigt sich dort mit Leben und Werk Johannes Keplers, ist
              Mitglied der Kepler-Gesellschaft, bietet ehrenamtlich Vorträge und
              Stadtführungen zu Kepler an. Zuletzt erschien von ihm ›Johannes
              Keplers Rolle im Hexenprozess seiner Mutter Katharina‹ im Katalog
              ›Himmelwärts. 450 Jahre Johannes Kepler‹ (Ausstellung im
              Stuttgarter Haus der Wirtschaft 2022).
            </Text>
          </Stack>
        </Box>
      </Box>
    </Container>
  )
}

export default Albinus
