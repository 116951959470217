import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Stack from "../../../components/stack"
import Heading from "../../../components/heading"

const EinfuehrungHoffnungen = (props) => {
  const data = useStaticQuery(graphql`
    query HoffnungenEinfuehrungQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/cover.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Paragraph dropcap={true}>
        Hölderlin kannte den Turm am Neckar bereits, bevor er hier Quartier
        erhielt. Von 1788 bis 1793 hatte er am Evangelischen Stift Theologie
        studiert, dabei jedoch nur umso mehr den Wunsch entwickelt,
        Schriftsteller zu werden.
      </Paragraph>
      <Image
        image={data.cover.childImageSharp.gatsbyImageData}
        alt="Bild des Raumes Tübinger Hoffnungen im ersten Stock des Turms. An der Wand Zitate und ein Freiheitsbaum, an der linken Wand eine graue Vitrine mit historischer Stadtansicht"
        attribution="David Franck"
      />
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Hölderlins Studienjahre in Tübingen
          </Heading>
          <Paragraph>
            Als 18-jähriger hatte Hölderlin das unweit gelegene Tübinger Stift
            bezogen – eine einzigartige und streng geführte Ausbildungsstätte
            für Theologen, an der er fünf Jahre studierte und dabei auch mit den
            später berühmten Philosophen Hegel und Schelling Freundschaft
            schloss.
          </Paragraph>
          <Paragraph>
            Am Ende seines ersten Studienjahrs wurde in Paris die Bastille
            gestürmt und die Französische Revolution berauschte mit ihren
            Idealen von Freiheit, Gleichheit und Brüderlichkeit ganz Europa und
            auch die jungen Stiftler. Hölderlin griff diese großen Themen in
            seinen hier entstandenen Hymnen auf und spiegelte sie auch im
            antiken Griechenland, das als Sehnsuchts- und Ideenraum sein
            weiteres Werk bestimmen wird. Einige der Tübinger Gedichte wurden am
            Ende seines Studiums gedruckt – Hölderlins erste Veröffentlichung.
          </Paragraph>
          <Paragraph>
            1793 schloss er sein Theologiestudium ab und wurde durch Schillers
            Vermittlung Hauslehrer. Als Pfarrer hat er nie gewirkt.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default EinfuehrungHoffnungen
