import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Schreibwerkstatt = () => {
  const data = useStaticQuery(graphql`
    query Schreibwerkstatt {
      schreibwerkstatt: file(
        relativePath: { eq: "veranstaltungen/postkarte_feuerteufel.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 760, height: 525, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Der Hölderlinturm lädt herzlich zur Schreibwerkstatt für Lyrik in leichter Sprache ein, die im Rahmen des Festivals „Literatur für alle“ stattfindet. 
        Sowohl Menschen mit als auch ohne Lernschwierigkeiten sind dazu eingeladen, unter der Leitung des Lyrikers und Theoretikers Daniel Falb 
        und Krishna-Sara Helmle vom Tübinger Büro für Leichte Sprache "Textöffner" Gedichte zu erschaffen.
      </Paragraph>
      <Paragraph>
        Der Workshop startet um 14 Uhr und endet voraussichtlich um 18 Uhr. Die Teilnahme ist frei.
      </Paragraph>
    </Stack>
  )
}

export default Schreibwerkstatt