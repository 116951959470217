import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Container from "../../../components/container"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

const IlmaRakusa = () => {
  const data = useStaticQuery(graphql`
    query VerliebtRakusa {
      rakusa: file(
        relativePath: { eq: "aus-dem-archiv-geholt/04/ilma-rakusa.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Container maxWidth="lg">
      <Box bg="black">
        <Box pr={[12, 24]}>
          <Image
            image={data.rakusa.childImageSharp.gatsbyImageData}
            attribution="Giorgio von Arb"
            alt="Porträt von Ilma Rakusa"
          />
        </Box>
        <Box p={[6, 12]}>
          <Stack space={6}>
            <Heading level={4} color="white">
              Ilma Rakusa
            </Heading>
            <Text size={[2, 3]} color="white">
              Ilma Rakusa (*1946) studierte Slawistik und Romanistik in Zürich,
              Paris und St. Petersburg. Sie lebt als Schriftstellerin,
              Kritikerin und Übersetzerin in Zürich. Ihr literarisches Werk
              umfasst Lyrik, Erzählungen, Essays und das Erinnerungsbuch ›Mehr
              Meer‹, 2020 erschien in der Grazer Literaturzeitschrift
              ›manuskripte‹ ihr Gedichtzyklus ›Corona mit Hölderlin‹. Sie
              erhielt zahlreiche Preise, u.a. den Adelbert-von- Chamisso-Preis
              (2003), den Schweizer Buchpreis (2009), den Berliner
              Literaturpreis (2017) und den Kleist-Preis (2019), und ist
              Mitglied der Deutschen Akademie für Sprache und Dichtung.
            </Text>
          </Stack>
        </Box>
      </Box>
    </Container>
  )
}

export default IlmaRakusa
