import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../../../components/image"
import Stack from "../../../components/stack"

const Landschaft = () => {
  const data = useStaticQuery(graphql`
    query {
      bodensee: file(
        relativePath: { eq: "aus-dem-archiv-geholt/06/bodensee.jpg" }
      ) {
        ...largeImage
      }
      hauptwil: file(
        relativePath: { eq: "aus-dem-archiv-geholt/06/hauptwil.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Stack space={3}>
        <Image
          image={data.bodensee.childImageSharp.gatsbyImageData}
          caption="Kolorierter Stahlstich von James Carter nach einer Zeichnung von Ludwig Mayer."
          attribution="Stadtarchiv Bad Homburg"
          alt="Eine historische Ansicht vom Bodensee mit der Stadt Lindau. Im Hintergrund sind die schweizer Berge zu sehen. Im Vordergrund fährt ein Kahn zwischen den hohen Wellen auf die Stadt Lindau zu. "
        />
      </Stack>
      <Stack space={3}>
        <Image
          image={data.hauptwil.childImageSharp.gatsbyImageData}
          caption="Johann Jakob Aschmann: Die Herrschaft Hauptweil im Obern Thurgau, No. 33, 1795."
          attribution="Zentralbibliothek Zürich"
          alt="Eine historische Ansicht von Hauptwil. Der Ort besteht aus einigen wenigen prächtigen Fachwerkhäusern und ist umgeben von Hügeln. Im Vordergrund macht eine Kutsche am Wegesrand Rast."
        />
      </Stack>
    </Stack>
  )
}

export default Landschaft
