import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Container from "../../../components/container"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Text from "../../../components/text"
import Small from "../../../components/small"
import Stack from "../../../components/stack"

const Portrait = () => {
  const data = useStaticQuery(graphql`
    query {
      portrait: file(
        relativePath: { eq: "aus-dem-archiv-geholt/07/portrait.jpg" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Container maxWidth="xl">
      <Stack space={0}>
        <Image
          image={data.portrait.childImageSharp.gatsbyImageData}
          alt="Ein Bild von Johannes Kepler. Er hat Locken und trägt einen runden Spitzen-Kragen um den Hals."
        />
        <Box p={8} bg="muted">
          <Stack space={6}>
            <Heading as="strong" level={5}>
              Johannes Kepler
            </Heading>
            <Text>
              Holzstich von Max Isaac Michael (auch Max Ma[e]yer; 1823–1891)
              nach dem sog. Pulkower Portrait. Trotz seines Alters von über 26
              Jahren, der Veröffentlichung seines ersten Werkes ›Mysterium
              Cosmographicum‹ 1596 und der ersten Heirat 1597 wirkt Kepler hier
              noch wie ein Student und Tübinger Stiftler, ein Beispiel für den
              Typus des jugendlichen Genies in der Kepler-Ikonographie des 19.
              Jahrhunderts.
            </Text>
            <Small color="whisper">
              Aus: Kepler-Denkmal, in: Freya, Illustrirte Blätter für die
              gebildete Welt, Bd. 5 (1865), S. 124–125.
            </Small>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default Portrait
