import React from "react"
import PropTypes from "prop-types"
import Box from "./box"

const Heading = (props) => {
  const {
    as,
    align,
    bold,
    level,
    fontFamily,
    color,
    textAlign,
    lineHeight,
    hyphens,
    italic,
    uppercase,
    sx,
    ...restProps
  } = props

  const headingSize = {
    1: [7, 8, 9],
    2: [6, 7, 8],
    3: [5, 6, 7],
    4: [4, 5, 6],
    5: [3, 4, 5],
    6: [2, 3, 4],
  }

  return (
    <Box
      as={as}
      textAlign={align || null}
      {...restProps}
      sx={{
        fontFamily: fontFamily,
        textTransform: uppercase
          ? "uppercase"
          : uppercase === false
          ? "none"
          : null,
        fontWeight: bold ? "bold" : bold === false ? "normal" : null,
        fontStyle: italic ? "italic" : italic === false ? "normal" : null,
        hyphens,
        lineHeight,
        color,
        fontSize: headingSize[level],
        ...sx,
      }}
    >
      {props.children}
    </Box>
  )
}

Heading.displayName = "Heading"

Heading.propTypes = {
  fontFamily: PropTypes.string,
  uppercase: PropTypes.bool,
  lineHeight: PropTypes.string,
  color: PropTypes.string,
  level: PropTypes.number,
}

Heading.defaultProps = {
  as: "h3",
  color: "text",
  lineHeight: "heading",
  fontFamily: "sans",
  bold: true,
}

export default Heading
