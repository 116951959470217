import React from "react"
import ReactModal from "react-modal"
import { css, Global, ClassNames } from "@emotion/react"
import { useThemeUI } from "theme-ui"
import SquareButton from "./square-button"
import Box from "./box"

const Modal = (props) => {
  const { children, onAfterOpen, isOpen, onClose, contentLabel } = props

  ReactModal.setAppElement("#___gatsby")

  const context = useThemeUI()
  const { theme } = context
  const background = theme.colors.modal

  const ReactModalGlobalStyles = () => (
    <Global
      styles={css`
        .ReactModal__Body--open {
          overflow: hidden;
        }
      `}
    />
  )

  const styleStrings = {
    modalBody: `
    width: 100%;
    height: 100%;
    &:focus {
      outline: none;
    }
  `,
    overlay: `
    z-index: ${theme.zIndices["60"]};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: auto;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background-color: ${background};
  `,
    overlayAfterOpen: `
    background-color: ${background};
    opacity: 1;
  `,
    overlayBeforeClose: `
    background-color: ${background};
    opacity: 0;
  `,
  }

  return (
    <>
      <ReactModalGlobalStyles />
      <ClassNames>
        {({ css, cx }) => (
          <ReactModal
            className={{
              base: cx(
                css`
                  ${styleStrings.modalBody};
                `
              ),
              afterOpen: "",
              beforeClose: "",
            }}
            overlayClassName={{
              base: css`
                ${styleStrings.overlay};
              `,
              afterOpen: css`
                ${styleStrings.overlayAfterOpen};
              `,
              beforeClose: css`
                ${styleStrings.overlayBeforeClose};
              `,
            }}
            onAfterOpen={onAfterOpen}
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel={contentLabel}
          >
            <Box position="fixed" right={6} top={6} zIndex="60">
              <SquareButton
                icon="close"
                onClick={onClose}
                aria-label="Schließen"
              />
            </Box>
            <Box width="full" px={6} my={24}>
              {children}
            </Box>
          </ReactModal>
        )}
      </ClassNames>
    </>
  )
}

export default Modal
