import React from "react"
import PropTypes from "prop-types"
import Box from "./box"

const Container = (props) => {
  const { maxWidth, children, center, ...restProps } = props

  return (
    <Box
      mx={center === true ? "auto" : null}
      maxWidth={maxWidth}
      {...restProps}
    >
      {children}
    </Box>
  )
}

Container.propTypes = {
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  size: PropTypes.number,
}

Container.defaultProps = {
  maxWidth: "8xl",
  center: true,
}

export default Container
