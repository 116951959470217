import React from "react" // eslint-disable-line no-unused-vars
import Box from "./box"
import Icon from "./icon"
import PropTypes from "prop-types"
import { alpha, tint } from "@theme-ui/color"

/**
 * `<IconButton/>` is used for close actions or when there's only limited space
 * available. Consider using Buttons with a label (e.g. with the "link" variant)
 * first and only make use of this component sparsely.
 */
const IconButton = ({
  variant,
  icon,
  color,
  href,
  onClick,
  size,
  ...restProps
}) => {
  const variants = {
    ghost: {
      bg: "transparent",
      p: 1,
      borderRadius: "full",
      color: color,
      ":hover, :focus": {
        color: tint(color, 0.2),
        bg: alpha(color, 0.1),
      },
    },
    plain: {
      bg: "transparent",
      color: color,
      p: 0,
      ":hover, :focus": {
        textDecoration: "underline",
        color: tint(color, 0.2),
      },
    },
  }

  const variantStyles = variants[variant]

  const sharedStyles = {
    alignItems: "center",
    appearance: "none",
    display: "inline-flex",
    justifyContent: "center",
    textDecoration: "none",
    verticalAlign: "middle",
  }

  return (
    <Box
      as={href ? "a" : "button"}
      href={href}
      onClick={onClick}
      type={href ? undefined : "button"}
      {...restProps}
      sx={{
        ...sharedStyles,
        ...variantStyles,
      }}
    >
      <Icon icon={icon} size={size} />
    </Box>
  )
}

IconButton.propTypes = {
  /**
   * Icon which should be used
   */
  icon: PropTypes.string.isRequired,
  /**
   * Link target
   */
  href: PropTypes.string,
  /**
   * color is used for the icon color in the `plain` and `ghost` variants
   */
  color: PropTypes.string,
  /**
   * Action
   */
  onClick: PropTypes.func,
  /**
   * Size
   */
  size: PropTypes.number,
  /**
   * Button Variant
   */
  variant: PropTypes.oneOf(["ghost", "plain"]),
}

IconButton.defaultProps = {
  variant: "ghost",
}

export default IconButton
