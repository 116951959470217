import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Lyrikhandlung = () => {
  const data = useStaticQuery(graphql`
    query Lyrikhandlung {
      lyrikhandlung: file(
        relativePath: { eq: "veranstaltungen/denkmal_header.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1000, height: 270, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        »Langgedichte, Kurzgedichte, Tanka und natürlich Sonette, diese Antriebsraketen für alle anderen Gedichtformen, 
        umfasst Thomas Kunsts neuer Gedichtband. Und am Ende eines jeden Kapitels steht das Meistersonett, ein Brief an seine Katze WÜ. 
        Voller Anmut und feinem Humor sind diese Verse, befremdend schön, und eine Art Schutzzauber gegen alles, was uns Angst macht, 
        gegen eine gewaltbereite Welt.« Aus der Klappenbeschreibung des Suhrkamp-Verlags.
      </Paragraph>
      <Paragraph>
        Im Eintritt inbegriffen wird Brot und Wein gereicht.
      </Paragraph>
      <Paragraph>
        Eine Veranstaltung der Lyrikhandlung am Hölderlinturm in Kooperation mit dem Museum Hölderlinturm und der Deutschen Akademie für Sprache und Dichtung e.V.
      </Paragraph>
    </Stack>
  )
}

export default Lyrikhandlung