import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "../../../components/grid"
import Columns from "../../../components/columns"
import Column from "../../../components/column"
import Poem from "../../../components/poem"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import Heading from "../../../components/heading"
import Text from "../../../components/text"
import AudioPlayer from "../../../components/audio-player"
import Stack from "../../../components/stack"
import ImageHotspots from "../../../components/image-hotspots"
import ImageHotspot from "../../../components/image-hotspot"

const Sommer = (props) => {
  const data = useStaticQuery(graphql`
    query JahreszeitenSommerQuery {
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-sommer.png"
        }
      ) {
        ...largeImage
      }
      sommer01: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/sommer-01.jpg"
        }
      ) {
        ...largeImage
      }
      sommer02: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/sommer-02.jpg"
        }
      ) {
        ...mediumImage
      }
      sommer03: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/sommer-03.jpg"
        }
      ) {
        ...mediumImage
      }
      sommer04: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/sommer-04.jpg"
        }
      ) {
        ...largeImage
      }
      sommer05: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/sommer-05.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading level={3}>{title}</Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <Stack>
      <Grid columns={[1, 2]} alignY="center">
        <Stack>
          <Heading level={3} color="summer">
            Der Sommer.
          </Heading>
          <Poem size="3">
            <span>Die Tage gehn vorbei mit sanffter Lüffte Rauschen,</span>
            <span>Wenn mit der Wolke sie der Felder Pracht vertauschen,</span>
            <span>Des Thales Ende trifft der Berge Dämmerungen,</span>
            <span>Dort, wo des Stromes Wellen sich hinabgeschlungen.</span>
            <span>
              <br />
            </span>
            <span>Der Wälder Schatten sieht umhergebreitet,</span>
            <span>Wo auch der Bach entfernt hinuntergleitet,</span>
            <span>Und sichtbar ist der Ferne Bild in Stunden,</span>
            <span>Wenn sich der Mensch zu diesem Sinn gefunden.</span>
            <span>
              <br />
            </span>
            <Columns>
              <Column>
                d. 24 Mai <br />
                1758.
              </Column>
              <Column></Column>
              <Column>Scardanelli.</Column>
            </Columns>
          </Poem>
          <Stack space={3}>
            <Text sans>
              Christian Reiner liest{" "}
              <Text bold as="span" color="summer">
                ›Der Sommer.‹
              </Text>
            </Text>
            <AudioPlayer
              src={`dauerausstellung/05-haelfte-des-lebens/sommer`}
            />
          </Stack>
        </Stack>
        <ImageHotspots
          caption="Hölderlins Gedichtmanuskript ›Der Sommer.‹ aus dem Deutschen Literaturarchiv Marbach"
          image={
            <Image
              image={data.gedicht.childImageSharp.gatsbyImageData}
              alt="Handschriftliches Manuskript von Hölderlins ›Der Sommer.‹"
            />
          }
        >
          <ImageHotspot x={86} y={17} name="schreibfluss">
            <ModalTemplate
              title="Im Schreibfluss"
              image={
                <Image
                  image={data.sommer01.childImageSharp.gatsbyImageData}
                  alt="Detailansicht des Schriftbilds"
                />
              }
            >
              <Paragraph>
                In Hölderlins ›Sommer‹-Gedicht ist alles in Bewegung: »Die Tage
                gehn vorbei«, die »Lüffte [r]auschen«, Wolken und Schatten
                verändern das Bild der Landschaft und der Strom schlingt sich
                durch das Tal. Diese Dynamik scheint sich auch auf das
                Schriftbild zu übertragen, das die Zeilengrenzen aufgebricht und
                sich stufenförmig seinen Weg über das Papier bahnt.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={88} y={6} name="marbach">
            <ModalTemplate
              title="Der Archivstempel"
              image={
                <Image
                  image={data.sommer02.childImageSharp.gatsbyImageData}
                  alt="Detailansicht des verblassten Archivstempels in der rechten oberen Ecke"
                />
              }
            >
              <Paragraph>
                Mit einem Stempel hat das einstige ›Schillermuseum Marbach
                a.N.‹, das heutige ›Deutsche Literaturarchiv Marbach‹, den
                Aufbewahrungsort des Manuskripts markiert.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={96} y={60} name="zeilenumbruch">
            <ModalTemplate
              title="Der Zeilenumbruch"
              image={
                <Image
                  image={data.sommer03.childImageSharp.gatsbyImageData}
                  alt="Trennzeichen und Zeilenumbruch in einem vergrößerten Ausschnitt des Manuskripts"
                />
              }
            >
              <Paragraph>
                Als folge Hölderlins Handschrift dem hier beschriebenen Bach,
                bricht sie genau dort, wo dieser »hinunter-gleitet«, in die
                nächste Zeile um.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={25} y={90} name="kommentar">
            <ModalTemplate
              title="Hölderlins Signatur, kommentiert von Lotte Zimmer"
              image={
                <Image
                  image={data.sommer04.childImageSharp.gatsbyImageData}
                  alt="Hölderlins Signatur und ein zweizeiliger Kommentar in einer anderen Handschrift"
                />
              }
            >
              <Paragraph>
                Hölderlin datiert das Gedicht auf »d.(en) 24. Mai 1758.« und
                signiert es mit dem Namen »Scardanelli«. Ernst Zimmers Tochter
                Lotte, die Hölderlin im Turm gepflegt hat, kommentiert darunter:
                »vor einige Tage schrieb Er dieses, unterschreibt aber immer
                diesen Namen, u lebt in seinen Gedanken, immer im 18ten
                Jahrhundert!«
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={68} y={32} name="entstehung">
            <ModalTemplate
              title="Spiegelungen der Rückseite"
              image={
                <Image
                  image={data.sommer05.childImageSharp.gatsbyImageData}
                  alt="Vergrößerte Darstellung des Manuskripts. Auf der Vorderseite scheint der Text der Rückseite hindurch"
                />
              }
            >
              <Paragraph>
                Von fremder Hand wurde auf der Rückseite das mutmaßlich reale
                Entstehungsdatum des Gedichts ergänzt, das hier durchscheint: Es
                soll im Juli 1842 entstanden sein.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
        </ImageHotspots>
      </Grid>
    </Stack>
  )
}

export default Sommer
