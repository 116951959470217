import React from "react"
import PropTypes from "prop-types"
import Box from "./box"

const Breakout = (props) => {
  const { children, breakout, ...restProps } = props

  const breakoutFromProps = breakout

  const resolveWidth = Array.isArray(breakoutFromProps)
    ? breakoutFromProps.map((value) => (value === false ? "initial" : "100vw"))
    : breakoutFromProps === false
    ? "initial"
    : "100vw"

  const resolveInset = Array.isArray(breakoutFromProps)
    ? breakoutFromProps.map((value) => (value === false ? "inherit" : "50%"))
    : breakoutFromProps === false
    ? "inherit"
    : "50%"

  const resolveMargin = Array.isArray(breakoutFromProps)
    ? breakoutFromProps.map((value) => (value === false ? "inherit" : "-50vw"))
    : breakoutFromProps === false
    ? "inherit"
    : "-50vw"

  return (
    <Box
      width={resolveWidth}
      position="relative"
      left={resolveInset}
      right={resolveInset}
      ml={resolveMargin}
      mr={resolveMargin}
      {...restProps}
    >
      <Box px={1}>{children}</Box>
    </Box>
  )
}

Breakout.propTypes = {
  breakout: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
}

Breakout.defaultProps = {
  breakout: true,
}

export default Breakout
