import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import Box from "./box"
import Icon from "./icon"

const ImageCompareHandle = () => {
  return (
    <Box
      bg="primary"
      width={12}
      height={12}
      borderRadius="full"
      display="flex"
      alignX="center"
      alignY="center"
      color="black"
    >
      <Icon icon="compare" />
    </Box>
  )
}

export default ImageCompareHandle
