import React from "react"
import PropTypes from "prop-types"
import Hotspot from "./hotspot"
import Modal from "./modal"
import Container from "./container"
import Stack from "./stack"

const ImageHotspot = (props) => {
  const { x, y, expanded, label, children, name, ...restProps } = props

  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [modalWasVisited, setWasVisited] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function afterOpenModal() {
    setWasVisited(true)
  }

  return expanded ? (
    <Stack space={12}>{children}</Stack>
  ) : (
    <>
      <Hotspot
        x={x}
        y={y}
        onClick={openModal}
        visited={modalWasVisited}
        label={label}
        {...restProps}
      />
      {children && (
        <Modal
          onAfterOpen={afterOpenModal}
          isOpen={modalIsOpen}
          onClose={closeModal}
          contentLabel={name}
        >
          <Container maxWidth="5xl">
            <Stack space={12}>{children}</Stack>
          </Container>
        </Modal>
      )}
    </>
  )
}

ImageHotspot.propTypes = {
  expanded: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
}

ImageHotspot.defaultProps = {
  expanded: false,
}

export default ImageHotspot
