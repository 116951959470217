import React from "react"
import Box from "./box"
import Icon from "./icon"
import PropTypes from "prop-types"
import { keyframes } from "@emotion/react"
import { alpha } from "@theme-ui/color"

const Hotspot = (props) => {
  const { x, y, color, bg, label, visited, ...restProps } = props

  const colorPulse = bg

  const pulse = keyframes({
    "0%": {
      boxShadow: (theme) =>
        `0 0 0 0 ${alpha(colorPulse, 0.7)(theme)}, 0 0 0 0 ${alpha(
          colorPulse,
          0.7
        )(theme)}`,
    },

    "20%": {
      boxShadow: (theme) =>
        `0 0 0 15px ${alpha(colorPulse, 0.7)(theme)}, 0 0 0 0 ${alpha(
          colorPulse,
          0.7
        )(theme)}`,
    },

    "40%": {
      boxShadow: (theme) =>
        `0 0 0 15px ${alpha(colorPulse, 0)(theme)}, 0 0 0 10px ${alpha(
          colorPulse,
          0
        )(theme)}`,
    },

    "60%": {
      boxShadow: (theme) =>
        `0 0 0 0 ${alpha(colorPulse, 0)(theme)}, 0 0 0 10px ${alpha(
          colorPulse,
          0
        )(theme)}`,
    },
  })

  return (
    <Box
      as="button"
      aria-label={label ? `Details öffnen: ${label}` : "Details öffnen"}
      bg={visited ? "black" : bg}
      color={color}
      position="absolute"
      borderRadius="full"
      display="flex"
      alignY="center"
      alignX="center"
      cursor="pointer"
      width={8}
      height={8}
      left={`${x}%`}
      top={`${y}%`}
      {...restProps}
      css={
        !visited
          ? {
              animationName: pulse.toString(),
              animationTimingFunction: "linear",
              animationDuration: "5000ms",
              animationDelay: "2500ms",
              animationIterationCount: "infinite",
            }
          : null
      }
    >
      <Icon icon={visited ? "done" : "plus"} />
    </Box>
  )
}

Hotspot.propTypes = {
  visited: PropTypes.bool,
  bg: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
}

Hotspot.defaultProps = {
  bg: "primary",
  color: "white",
}

export default Hotspot
