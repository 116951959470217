/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Box from "./box"

const Divider = (props) => {
  const { size, color, ...restProps } = props

  return <Box borderBottom={size} borderColor={color} {...restProps} />
}

Divider.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Divider.defaultProps = {
  size: 1,
  color: "border",
}

export default Divider
