import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Falb = () => {
  const data = useStaticQuery(graphql`
    query Falb {
      falb: file(
        relativePath: { eq: "veranstaltungen/postkarte_feuerteufel.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 760, height: 525, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Im Rahmen des Festivals „Literatur für alle“ findet eine Lesung des Lyrikers und Theoretikers Daniel Falb im Hölderlinturm statt.
      </Paragraph>
      <Paragraph>
        Beginn der Lesung ist um 19 Uhr. Der Eintritt ist frei.
      </Paragraph>
    </Stack>
  )
}

export default Falb