import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Container from "../../../components/container"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

const NadjaKuechenmeister = (props) => {
  const data = useStaticQuery(graphql`
    query AussichtNadjaQuery {
      nadja: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/03/nadja-kuechenmeister.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Container maxWidth="lg">
      <Box bg="black">
        <Box pr={[12, 24]}>
          <Image
            image={data.nadja.childImageSharp.gatsbyImageData}
            attribution="Dirk Skiba"
            alt="Schwarz-Weiß Porträt von Nadja Küchenmeister"
          />
        </Box>
        <Box p={[6, 12]}>
          <Stack space={6}>
            <Heading level={4} color="white">
              Nadja Küchenmeister
            </Heading>
            <Text size={[2, 3]} color="white">
              Nadja Küchenmeister wurde 1981 in Berlin geboren. Sie studierte
              Germanistik und Soziologie an der Technischen Universität in
              Berlin sowie am Deutschen Literaturinstitut in Leipzig. Neben
              diversen Lehrtätigkeiten, u.a. am Deutschen Literaturinstitut und
              derzeit an der Kunsthochschule für Medien in Köln, schreibt sie
              Hörspiele und Features für den Rundfunk. Gemeinsam mit Matthias
              Kniep übersetzt sie aus dem Englischen (zuletzt: ›Mehrstimmiger
              Gesang‹ von Denise Riley, hochroth). Ihr erster Gedichtband ›Alle
              Lichter‹ erschien 2010, 2014 folgte ›Unter dem Wacholder‹, 2020
              ›Im Glasberg‹ (alle bei Schöffling &. Co., Frankfurt am Main). Für
              ihre Lyrik wurde sie vielfach ausgezeichnet, u.a. mit dem Mondseer
              Lyrikpreis, dem Ulla-Hahn Autorenpreis sowie dem Förderpreis des
              Bremer Literaturpreises.
            </Text>
          </Stack>
        </Box>
      </Box>
    </Container>
  )
}

export default NadjaKuechenmeister
