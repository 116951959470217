import PropTypes from "prop-types"
import React from "react"

import Box from "./box"

const Grid = (props) => {
  const {
    as: element,
    children,
    columns,
    alignX,
    alignY,
    reverse,
    space,
    ...restProps
  } = props

  const isList = element === "ol" || element === "ul"

  const spaceFromProps = space
  const negativeSpace = Array.isArray(spaceFromProps)
    ? spaceFromProps.map((value) => (value !== 0 ? -value : value))
    : space * -1

  const columnsFromProps = columns
  const columnWidth = Array.isArray(columnsFromProps)
    ? columnsFromProps.map((value) => (value === 1 ? "full" : `1/${value}`))
    : columnsFromProps === 1
    ? "full"
    : `1/${columnsFromProps}`

  const reverseFromProps = reverse
  const resolveReverse = Array.isArray(reverseFromProps)
    ? reverseFromProps.map((value) => (value === true ? "row-reverse" : "row"))
    : reverseFromProps === true
    ? "row-reverse"
    : "row"

  return (
    <Box
      as={element}
      display="flex"
      flexDirection={resolveReverse}
      alignX={alignX}
      alignY={alignY}
      flexWrap="wrap"
      mt={isList ? "0px" : null}
      pl={isList ? "0px" : null}
      listStyleType={isList ? "none" : null}
      mb={negativeSpace}
      ml={negativeSpace}
      {...restProps}
    >
      {React.Children.toArray(children).map((child, i) => {
        return (
          <Box
            as={isList ? "li" : "div"}
            key={i}
            width={columnWidth}
            pl={space}
            pb={space}
          >
            {child}
          </Box>
        )
      })}
    </Box>
  )
}

Grid.propTypes = {
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  alignX: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  alignY: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  reverse: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  columns: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

Grid.defaultProps = {
  space: [6, 12],
}

export default Grid
