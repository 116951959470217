import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import PoemHaelfteDesLebens from "../../shared/poem-haelfte-des-lebens"

const GangLeuchten = (props) => {
  const data = useStaticQuery(graphql`
    query RaumGangLeuchtenQuery {
      leuchten: file(
        relativePath: {
          eq: "dauerausstellung/03-ein-gang-von-42-schuh-laenge/leuchten.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack space={[12, 24]}>
      <Stack>
        <Constrain>
          <Stack>
            <Heading as="h2" level={2}>
              Hälfte des Lebens
            </Heading>
            <Paragraph>
              Die Lichtschienen im Erdgeschoss des Turms greifen das metrische
              Schema der letzten beiden Verse von Hölderlins bekanntestem
              Gedicht ›Hälfte des Lebens‹ auf.
            </Paragraph>
          </Stack>
        </Constrain>
        <Image
          image={data.leuchten.childImageSharp.gatsbyImageData}
          alt="Detailansicht der Lichtschienen. Auf den langen Schienen stehen die betonten, auf den kurzen die unbetonten Silben aus ›Hälfte des Lebens‹"
        />
        <Constrain>
          <Stack>
            <Paragraph>
              1799 begonnen und 1805 erstmals veröffentlicht, nimmt der Titel
              bereits jene Wende vorweg, die Hölderlins Leben in zwei Hälften
              teilen wird. Er greift in seiner metrischen Gestaltung aber auch
              den Adonius, ein antikes Versmaß, benannt nach den Klagen um den
              Tod des Adonis, auf und gibt damit ein Beispiel für Hölderlins
              innovative Versexperimente: In dem gesamten, freirhythmisch
              komponierten Gedicht fächert Hölderlin den Reichtum der antiken
              Verskunst auf.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain>
          <Stack>
            <Heading as="h3" level={5}>
              Versmaße in Hölderlins ›Hälfte des Lebens‹
            </Heading>
            <PoemHaelfteDesLebens />
          </Stack>
        </Constrain>
      </Stack>
      <Constrain>
        <Stack>
          <Heading level={5}>Mehr zur Metrik von ›Hälfte des Lebens‹</Heading>
          <Stack space={3}>
            <Text size={3}>
              <Heading as="span">Wolfram Groddeck</Heading>:{" "}
              <TextLink href="https://www.transcript-verlag.de/978-3-89942-606-9/weiterlesen/">
                Zahl, Maß und Metrik in Hölderlins Gedicht ›Hälfte des Lebens‹
              </TextLink>
            </Text>
            <Text color="whisper">
              In: Weiterlesen. Literatur und Wissen, hrsg. von Ulrike Bergermann
              und Elisabeth Strowick, Bielefeld 2007
            </Text>
          </Stack>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default GangLeuchten
