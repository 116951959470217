import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Freiheit = () => {
  const data = useStaticQuery(graphql`
    query Freiheit {
      literatursommer: file(
        relativePath: { eq: "veranstaltungen/literatursommer24.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 550, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Auf den Lesungen der Tübinger Autorin Andrea Mittag verschwimmen Text, Musik und Performance,
        es bleibt allein das synästhetische Erlebnis – »ExperimenTelling« nennt sie diese Praxis. Ihre Texte handeln häufig von Zwang, 
        Grenzen und Abhängigkeit. Eigens zum Literatursommer hat sie eine Textperformance zum Thema »Freiheit« erarbeitet.
      </Paragraph>
      <Paragraph> 
        Eine Veranstaltung im Rahmen des{" "}
        <ExternalLink href="https://www.literatursommer.de/de/">
          <span style={{color: '#F7A61B'}}> Literatursommers Baden-Württemberg</span> 
        </ExternalLink>
        .
      </Paragraph>
      <Grid columns={2} space={"px"}>
        <Image
          image={data.literatursommer.childImageSharp.gatsbyImageData}
          alt="Logo des Literatursommer 2024" 
        />
      </Grid>
    </Stack>
  )
}

export default Freiheit