import React from "react"
import Text from "./text"
import PropTypes from "prop-types"

const Paragraph = ({
  children,
  size,
  hyphens,
  lang,
  dropcap,
  ...restProps
}) => {
  return (
    <Text
      size={size}
      lang={lang}
      hyphens={hyphens}
      serif
      sx={{
        variant: dropcap ? "text.dropcap" : null,
      }}
      {...restProps}
    >
      {children}
    </Text>
  )
}

Paragraph.propTypes = {
  hyphens: PropTypes.string,
  lang: PropTypes.string,
  dropcap: PropTypes.bool,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

Paragraph.defaultProps = {
  hyphens: "auto",
  lang: "de",
  dropcap: false,
  size: [3, 3, 4, 5],
}

export default Paragraph
