import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

const Autorinnen = () => {
  const data = useStaticQuery(graphql`
    query {
      jaeckel: file(
        relativePath: { eq: "aus-dem-archiv-geholt/06/stephanie-jaeckel.jpg" }
      ) {
        ...mediumImage
      }
      lewandowsky: file(
        relativePath: { eq: "aus-dem-archiv-geholt/06/via-lewandowsky.jpg" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Grid columns={[1, 2]} space={3}>
      <Box bg="black">
        <Box pr={[12, 24]}>
          <Image
            image={data.jaeckel.childImageSharp.gatsbyImageData}
            attribution="Anna Rozkosny"
            alt="Eine Fotografie von Stephanie Jaeckel. Sie hat kurze, blonde Haare und trägt eine Brille."
          />
        </Box>
        <Box p={[6, 12]}>
          <Stack space={6}>
            <Heading level={4} color="white">
              Stephanie Jaeckel
            </Heading>
            <Text size={[2, 3]} color="white">
              Stephanie Jaeckel hat Kunstgeschichte, Romanistik und Neuere
              Geschichte in Bonn und Berlin studiert. Mittlerweile lebt sie seit
              über 20 Jahren in Berlin-Kreuzberg, wo sie neben Artikeln,
              Hörtexten und Podcasts auch Texte in Einfacher und Leichter
              Sprache schreibt. 2021 erschien ihr Buch ›Hölderlin leuchtet‹ zu
              der im Hölderlinturm gezeigten Sonderausstellung ›Hölderlin
              liebt…‹. Ihr Kinderhörspiel ›Kimmo ahoi!‹ kam 2014 als eines der
              fünf besten Sachhörbücher in den Kategorien Regie und Text auf die
              Longlist des Beo-Hörspielpreises.
            </Text>
          </Stack>
        </Box>
      </Box>
      <Box bg="black">
        <Box pr={[12, 24]}>
          <Image
            image={data.lewandowsky.childImageSharp.gatsbyImageData}
            attribution="Thomas Kretschel"
            alt="Eine Schwarz-weiß-Fotografie von Via Lewandowsky. Er trägt eine Brille und hat den Kopf auf die Hand gestützt."
          />
        </Box>
        <Box p={[6, 12]}>
          <Stack space={6}>
            <Heading level={4} color="white">
              Via Lewandowsky
            </Heading>
            <Text size={[2, 3]} color="white">
              Via Lewandowsky, geboren 1963, ist ein deutscher Künstler, der mit
              den Medien Fotografie, Installation, Malerei, Objektkunst,
              Performance und Skulptur arbeitet. Neben der Teilnahme an der
              documenta IX, wurde er vor allem durch seine
              Ausstellungs­szenografien und Kunst-am-Bau-Projekte bekannt.
              Leitmotive sind immer wieder das Missverständnis als Scheitern von
              Kommunikation, das Prozesshafte, die ironische Brechung des
              Alltäglichen, sowie das Eindringen des Fremden in den vertrauten,
              meist häuslichen, Bereich.
            </Text>
          </Stack>
        </Box>
      </Box>
    </Grid>
  )
}

export default Autorinnen
