import React, { useContext } from "react"
import PropTypes from "prop-types"

import { ColumnsContext } from "./columns"
import Box from "./box"

const Column = (props) => {
  const { children, width, ...restProps } = props
  const space = useContext(ColumnsContext)

  return (
    <Box
      minWidth={0}
      width={
        width === undefined ? "full" : width === "content" ? undefined : width
      }
      flexShrink={width !== undefined || width === "content" ? 0 : undefined}
      pl={space}
      pt={space}
      {...restProps}
    >
      {children}
    </Box>
  )
}

Column.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

export default Column
