import React from "react"
import Accent from "../../components/accent"
import Inline from "../../components/inline"
import Poem from "../../components/poem"
import Stack from "../../components/stack"
import WordFlip from "../../components/word-flip"

const PoemHaelfteDesLebens = () => {
  return (
    <Stack space={[12, 16]}>
      <Stack space={1}>
        <Poem>
          <span>
            <Accent>Hälf</Accent>te des <Accent>Le</Accent>bens
          </span>
        </Poem>
        <WordFlip flipped="Adonius">— ◡ ◡ — ◡</WordFlip>
      </Stack>
      <Stack space={6}>
        <Stack space={1}>
          <Poem>
            <span>
              Mit <Accent>gel</Accent>ben <Accent>Bir</Accent>nen{" "}
              <Accent>hän</Accent>get
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Alkäischer Fünfsilber">◡ — ◡ — ◡</WordFlip>
            <WordFlip flipped="Trochäus">— ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Und <Accent>voll</Accent> mit <Accent>wil</Accent>den{" "}
              <Accent>Ro</Accent>sen
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Alkäischer Fünfsilber">◡ — ◡ — ◡</WordFlip>
            <WordFlip flipped="Trochäus">— ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Das <Accent>Land</Accent> in den <Accent>See</Accent>,
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Jambus">◡ —</WordFlip>
            <WordFlip flipped="Anapäst">◡ ◡ —</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Ihr <Accent>hol</Accent>den <Accent>Schwä</Accent>ne,
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Alkäischer Fünfsilber">◡ — ◡ — ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Und <Accent>trun</Accent>ken von <Accent>Küs</Accent>sen
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Akephaler Pherekrateus">◡ — ◡ ◡ — ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              <Accent>Tunkt</Accent> ihr das <Accent>Haupt</Accent>
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Chorjambus">— ◡ ◡ —</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Ins <Accent>hei</Accent>lig<Accent>nüch</Accent>terne{" "}
              <Accent>Was</Accent>ser.
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Amphibrachys">◡ — ◡</WordFlip>
            <WordFlip flipped="Adonius">— ◡ ◡ — ◡</WordFlip>
          </Inline>
        </Stack>
      </Stack>
      <Stack space={6}>
        <Stack space={1}>
          <Poem>
            <span>
              <Accent>Weh</Accent> <Accent>mir</Accent>, wo{" "}
              <Accent>nehm</Accent>' ich, wenn
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Spondeus">— —</WordFlip>
            <WordFlip flipped="2. Päon">◡ — ◡ ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Es <Accent>Win</Accent>ter ist, die <Accent>Blu</Accent>men, und{" "}
              <Accent>wo</Accent>
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="2. Päon">◡ — ◡ ◡</WordFlip>
            <WordFlip flipped="Amphibrachy">◡ — ◡</WordFlip>
            <WordFlip flipped="Jambus">◡ —</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Den <Accent>Son</Accent>nenschein,
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="2. Päon">◡ — ◡ ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Und <Accent>Schat</Accent>ten der <Accent>Er</Accent>de?
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Akephaler Pherekrateus">◡ — ◡ ◡ — ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              Die <Accent>Mau</Accent>ern stehn
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="2. Päon">◡ — ◡ ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              <Accent>Sprach</Accent>los und <Accent>kalt</Accent>, im{" "}
              <Accent>Win</Accent>de
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Chorjambus">— ◡ ◡ —</WordFlip>
            <WordFlip flipped="Amphibrachys">◡ — ◡</WordFlip>
          </Inline>
        </Stack>
        <Stack space={1}>
          <Poem>
            <span>
              <Accent>Klir</Accent>ren die <Accent>Fah</Accent>nen.
            </span>
          </Poem>
          <Inline>
            <WordFlip flipped="Adonius">— ◡ ◡ — ◡</WordFlip>
          </Inline>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default PoemHaelfteDesLebens
