import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Paragraph from "../../../components/paragraph"
import Columns from "../../../components/columns"
import Column from "../../../components/column"
import Constrain from "../../../components/constrain"
import Heading from "../../../components/heading"
import ReadMore from "../../../components/read-more"
import NoBreak from "../../../components/no-break"
import ImageHotspot from "../../../components/image-hotspot"
import ImageHotspots from "../../../components/image-hotspots"
import Blockquote from "../../../components/blockquote"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

const Hyperion = (props) => {
  const data = useStaticQuery(graphql`
    query HyperionLinienDesLebensQuery {
      hyperionZimmer: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/hyperion-ausgabe-zimmer.jpg"
        }
      ) {
        ...largeImage
      }
      hyperionAufgeklappt: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/hyperion-aufgeklappt.jpg"
        }
      ) {
        ...largeImage
      }
      hyperion1: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/hyperion-1.jpg"
        }
      ) {
        ...mediumImage
      }
      hyperion2: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/hyperion-2.jpg"
        }
      ) {
        ...mediumImage
      }
      hyperion3: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/hyperion-3.jpg"
        }
      ) {
        ...mediumImage
      }
      hyperion4: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/hyperion-4.jpg"
        }
      ) {
        ...mediumImage
      }
      hyperion5: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/hyperion-5.jpg"
        }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Stack>
      <Heading as="h2" level={2}>
        Ernst Zimmers <NoBreak>›Hyperion‹-Ausgabe</NoBreak>
      </Heading>
      <Constrain align="left">
        <Image
          image={data.hyperionZimmer.childImageSharp.gatsbyImageData}
          alt="Vitrine mit einer alten Ausgabe von Hölderlins ›Hyperion‹ aus dem Besitz von Ernst Zimmer"
        />
      </Constrain>
      <Constrain>
        <Stack>
          <Paragraph>
            Bevor der Schreinermeister Zimmer den zwei Jahre älteren Hölderlin
            persönlich traf, hatte er ihn über seinen Briefroman{" "}
            <NoBreak>›Hyperion‹</NoBreak> kennengelernt. Nach der Lektüre, die
            Zimmer »ungemein wohl gefiel«, besuchte er den Autor im hiesigen{" "}
            <ReadMore
              content={
                <Constrain>
                  <Paragraph>
                    Gegen seinen Willen war Hölderlin im September 1806 ins
                    Tübinger Klinikum eingewiesen worden. Zuvor in Bad Homburg
                    als Hofbibliothekar tätig, hatte er teil an den
                    umstürzlerischen Plänen seines Freundes Isaac von Sinclair,
                    die zu einem Hochverratsprozess führten. Ein ärztliches
                    Gutachten bewahrte den Dichter, der sich »in einem höchst
                    traurigen Gemütszustand« befand und zur{" "}
                    <NoBreak>»Raserey«</NoBreak> neigte, vor der Verfolgung.
                    Sinclair hatte den Zustand seines Freundes aber auch einmal
                    »eine aus wohl überdachten Gründen angenommene Äußerungs
                    Art« genannt. Zuletzt sah er sich gezwungen, den verwirrten
                    Freund in die Obhut seiner Mutter zu geben, die ihren Sohn
                    ins Tübinger Klinikum bringen ließ.
                  </Paragraph>
                </Constrain>
              }
            >
              Klinikum
            </ReadMore>
          </Paragraph>
          <Paragraph>
            Als Hölderlin nach 231 Tagen Behandlung als »unheilbar« krank
            entlassen wurde, nahm ihn die Familie Zimmer in ihrem Haus auf. Aus
            den Hölderlin in Aussicht gestellten 2-3 restlichen Lebensjahren
            wurden 36.
          </Paragraph>
        </Stack>
      </Constrain>
      <ImageHotspots
        caption="Erstausgabe von Hölderlins Roman ›Hyperion‹ aus dem Besitz von Ernst Zimmer, Deutsches Literaturarchiv Marbach"
        image={
          <Image
            image={data.hyperionAufgeklappt.childImageSharp.gatsbyImageData}
            alt="Aufgeschlagene Erstausgabe mit handschriftlichen Einträgen in der Titelei"
          />
        }
      >
        <ImageHotspot x={90} y={4} name="zimmer">
          <Stack>
            <Heading level={3}>Besitzvermerk von Ernst Zimmer</Heading>
            <Columns space={12} collapse={[true, true, false]}>
              <Column width={["full", "60%", "40%"]}>
                <Image
                  image={data.hyperion1.childImageSharp.gatsbyImageData}
                  alt="Besitzvermerk in vergrößerter Darstellung. Rechts oben ist noch immer der Namenszug »Zimmer« zu erkennen"
                />
              </Column>
              <Column>
                <Stack>
                  <Paragraph>
                    In dieser Erstausgabe von Hölderlins ›Hyperion‹ ist rechts
                    oben noch immer der Namenszug »Zimmer« zu erkennen – und das
                    obwohl der spätere Besitzer wohl versucht hatte, den Namen
                    des Vorbesitzers auszuradieren.
                  </Paragraph>
                  <Paragraph>
                    Im Brief an einen Unbekannten erinnert sich Zimmer 30 Jahre
                    später an seine erste Lektüre des Romans zurück:
                  </Paragraph>
                  <Paragraph>
                    <Blockquote>
                      »Damals habe ich seinen [Hölderlins]{" "}
                      <Text as="em" italic>
                        Hipperion
                      </Text>{" "}
                      mit der Frau Hoffbuchbinder Bliefers geleßen welcher mir
                      ungemein wohl gefiel. Ich besuchte Hölderlin im Clinikum
                      und Bedauerte [...] sehr, daß ein so schöner Herlicher
                      Geist zu Grund gehen soll. Da im Clinikum nichts wieter
                      mit Hölderlin zu machen war, so machte der Cazler Auenrith
                      mir den Vorschlag Hölderlin in mein Haus aufzunehmen, er
                      wüßte kein pasenderes Lokal.«
                    </Blockquote>
                  </Paragraph>
                </Stack>
              </Column>
            </Columns>
          </Stack>
        </ImageHotspot>
        <ImageHotspot x={55} y={10} name="koch-1">
          <Stack>
            <Heading level={3}>War es gar Hölderlins Arbeitsexemplar?</Heading>
            <Columns space={12} collapse={[true, true, false]}>
              <Column width={["full", "60%", "40%"]}>
                <Image
                  image={data.hyperion2.childImageSharp.gatsbyImageData}
                  alt="Handschriftliche Notizen von Dr. Carl Adolph Louis Koch"
                />
              </Column>
              <Column>
                <Stack>
                  <Paragraph>
                    Das Buch ging später in den Besitz des Laichinger
                    Psychiatriedirektors Dr. Carl Adolph Louis Koch (1804–1887)
                    über, der darin zahlreiche Hervorhebungen und Notizen
                    hinterlassen, ja stellenweise sogar abgewetzte Buchstaben
                    nachgezeichnet hat.
                  </Paragraph>
                  <Paragraph>
                    In diesem handschriftlichen Eintrag in der Titelei des
                    Buches behauptet er:
                  </Paragraph>
                  <Paragraph>
                    <Blockquote>
                      »Es ist gegenwärtiges Exemplar dasjenige welches Hölderlin
                      für sich benutzte, u. noch in den Tagen hatte wo sein
                      Geist umnachtet für hier, wie es die Menschen nennen,
                      vielleicht aber deßhalb nur oft abwesend erscheinen mußte,
                      weil er vorauseilen durfte von Zeit zu Zeit in das Land
                      des Schauens.«
                    </Blockquote>
                  </Paragraph>
                </Stack>
              </Column>
            </Columns>
          </Stack>
        </ImageHotspot>
        <ImageHotspot x={75} y={35} name="koch-2">
          <Stack>
            <Heading level={3}>Kochs Begegnungen mit Hölderlin</Heading>
            <Columns space={12} collapse={[true, true, false]}>
              <Column width={["full", "60%", "40%"]}>
                <Image
                  image={data.hyperion3.childImageSharp.gatsbyImageData}
                  alt="weitere Notizen von Dr. Carl Adolph Louis Koch"
                />
              </Column>
              <Column>
                <Stack>
                  <Paragraph>
                    Carl Adolph Louis Koch, der ab 1826 in Tübingen Medizin
                    studierte und wohl ein großer Bewunderer von Hölderlins
                    Werken war, erinnert sich zurück:
                  </Paragraph>
                  <Paragraph>
                    <Blockquote>
                      »Ich sah Hölderlin oft bei Schreiner Zimmer in Tübingen.
                      Seine Freude war dem sanften Tönen der Eolsharfen lange,
                      lange zuzuhören.«
                    </Blockquote>
                  </Paragraph>
                </Stack>
              </Column>
            </Columns>
          </Stack>
        </ImageHotspot>
        <ImageHotspot x={60} y={50} name="koch-3">
          <Stack>
            <Heading level={3}>Spätere Revision</Heading>
            <Columns space={12} collapse={[true, true, false]}>
              <Column width={["full", "60%", "40%"]}>
                <Image
                  image={data.hyperion4.childImageSharp.gatsbyImageData}
                  alt="Handschriftliche Notiz von Dr. Carl Adolph Louis Koch mit dunklerer Tinte geschrieben"
                />
              </Column>
              <Column>
                <Stack>
                  <Paragraph>
                    Zu einem wahrscheinlich deutlich späteren Zeitpunkt und mit
                    dunklerer Tinte revidiert Koch das zuvor Geschriebene:
                  </Paragraph>
                  <Paragraph>
                    <Blockquote>
                      »Dachte einst, vor vielen Jahren! also, als ich selber
                      noch auf der Oberfläche der gewöhnlichen Erkenntniß
                      schwebte; nun denke ich ganz anders, da ich durch den
                      erbarmenden Zug göttlicher Gnade zu der Erkenntniß habe
                      kommen dürfen, daß es nur einer in Gott u. Christo
                      ruhenden Seele wahrhaft licht ist, u. nur eine solche
                      Seele, im Lichte der ewigen Wahrheit, wirkliche Wahrheit
                      erschaut. Alles Übrige ist mehr oder weniger in Wahnsinn.
                      Hölderlin gehört wohl zu den besseren Mensehen, aber ist,
                      leider! auch einer der Unglüklichen von denen es heißt:
                      Wir Narren haben des rechten Weges verfehlt.«
                    </Blockquote>
                  </Paragraph>
                </Stack>
              </Column>
            </Columns>
          </Stack>
        </ImageHotspot>
        <ImageHotspot x={80} y={70} name="koch-sohn">
          <Stack>
            <Heading level={3}>Das Buch wandert weiter</Heading>
            <Columns space={12} collapse={[true, true, false]}>
              <Column width={["full", "60%", "40%"]}>
                <Image
                  image={data.hyperion5.childImageSharp.gatsbyImageData}
                  alt="Handschriftliche Ergänzung von Kochs Sohn Dr. Julius Ludwig August Koch"
                />
              </Column>
              <Column>
                <Stack>
                  <Paragraph>
                    Nach Carl Adolph Louis Kochs Tod wandert das Buch in den
                    Besitz seines Sohnes Dr. Julius Ludwig August Koch
                    (1841–1908), Psychiatriedirektor in Zwiefalten. Dieser
                    wiederum relativiert den Nachtrag seines Vaters, indem er
                    hinzufügt:
                  </Paragraph>
                  <Paragraph>
                    <Blockquote>
                      »Diese letztere Äußerung meines frommen u. liebevollen
                      Vaters ist, was ihre medizinische Seite betrifft, aus
                      wissenschaftlichen Anschauungen der damaligen Zeit heraus
                      zu verstehen.«
                    </Blockquote>
                  </Paragraph>
                </Stack>
              </Column>
            </Columns>
          </Stack>
        </ImageHotspot>
      </ImageHotspots>
    </Stack>
  )
}

export default Hyperion
