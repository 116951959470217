import React from "react"
import PropTypes from "prop-types"

import Text from "./text"

const Caption = (props) => {
  const { children, align, ...restProps } = props

  return (
    <Text {...restProps} align={align} size={[1, 1, 2]} bold sans>
      {children}
    </Text>
  )
}

Caption.propTypes = {
  align: PropTypes.string,
}

Caption.defaultProps = {
  align: "center",
}

export default Caption
