import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Button from "../../../components/button"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Container from "../../../components/container"
import Image from "../../../components/image"
import Stack from "../../../components/stack"
import Heading from "../../../components/heading"
import Text from "../../../components/text"

const AttributionChristianReiner = (props) => {
  const data = useStaticQuery(graphql`
    query AttributionChristianReinerQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/cover-christian-reiner-ecm.jpg"
        }
      ) {
        ...smallImage
      }
    }
  `)

  return (
    <Box bg="muted" p={[6, 8, 12]}>
      <Columns
        alignY="center"
        space={[6, 8, 12]}
        collapse={[true, false]}
        reverse={[false, true]}
      >
        <Column width="content">
          <Box width={["24", "40"]}>
            <Image
              image={data.cover.childImageSharp.gatsbyImageData}
              alt="Titelbild des Ausstellungkatalogs"
            />
          </Box>
        </Column>
        <Column>
          <Stack space={6}>
            <Stack space={3}>
              <Stack space={2}>
                <Heading as="span" level={5}>
                  Christian Reiner spricht Hölderlins Turmgedichte
                </Heading>
                <Container maxWidth="lg" center={false}>
                  <Text size={[2, 3]} sans>
                    Der Stimm- und Sprechkünstler Christian Reiner hat 25 der 48
                    Turmgedichte auf CD eingesprochen.
                  </Text>
                </Container>
              </Stack>
              <Text size={1} sans>
                Erschienen 2012 bei ECM
              </Text>
            </Stack>
            <Button
              secondary
              href="https://www.ecmrecords.com/shop/143038752815/friedrich-holderlin-turmgedichte-christian-reiner-christian-reiner"
            >
              Jetzt ansehen
            </Button>
          </Stack>
        </Column>
      </Columns>
    </Box>
  )
}

export default AttributionChristianReiner
