import React from "react"
import PropTypes from "prop-types"
import { ResponsivePie } from "@nivo/pie"
import { useThemeUI } from "theme-ui"
import Box from "./box"

const PieChart = (props) => {
  const { height, ...passedProps } = props

  const context = useThemeUI()
  const { theme } = context

  /*
   * Default theming options can be found here:
   * https://github.com/plouc/nivo/blob/bd008153c80295d0f0c719c30b318b940d2559dc/packages/core/src/theming/defaultTheme.js
   */
  const chartTheme = {
    background: "transparent",
    fontFamily: theme.fonts.heading,
    fontSize: 12,
    textColor: theme.colors.text,
    axis: {
      domain: {
        line: {
          stroke: "transparent",
          strokeWidth: 1,
        },
      },
      ticks: {
        line: {
          stroke: theme.colors.whisper,
          strokeWidth: 1,
        },
      },
    },
    grid: {
      line: {
        stroke: theme.colors.border,
        strokeWidth: 1,
      },
    },
    tooltip: {
      container: {
        background: "white",
        color: "black",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
        padding: "5px 9px",
      },
    },
  }

  return (
    <Box height={height} fontFamily="sans">
      <ResponsivePie
        colors={{ scheme: "category10" }}
        theme={chartTheme}
        labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        {...passedProps}
      />
    </Box>
  )
}

PieChart.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

PieChart.defaultProps = {
  height: 92,
}

export default PieChart
