import React, { useState } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Box from "./box"
import Text from "./text"
import Icon from "./icon"
import Inline from "./inline"

const WordFlip = (props) => {
  const { children, flipped, ...restProps } = props

  const [state, setState] = useState({
    isFlipped: false,
  })

  const toggleFlip = () => {
    setState({
      ...state,
      isFlipped: !state.isFlipped,
    })
  }

  return (
    <Box
      as="button"
      display="inline-flex"
      borderRadius="full"
      bg="muted"
      py={1}
      px={2}
      {...restProps}
      onClick={toggleFlip}
      cursor="pointer"
      userSelect="none"
      aria-label="umdrehen"
    >
      <Inline space={1}>
        <Text sans>{state.isFlipped ? flipped : children}</Text>
        <Icon
          icon={state.isFlipped ? "circle-remove" : "circle-add"}
          color={state.isFlipped ? "secondary" : "primary"}
        />
      </Inline>
    </Box>
  )
}

WordFlip.propTypes = {
  flipped: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.number,
  ]),
}

export default WordFlip
