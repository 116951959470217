import React from "react"
import Paragraph from "../../../components/paragraph"

const DateHeading = ({ children }) => {
  return (
    <Paragraph align="right" color="whisper">
      {children}
    </Paragraph>
  )
}

export default DateHeading
