import React from "react"

import Grid from "../../../components/grid"
import Stack from "../../../components/stack"
import Poem from "../../../components/poem"
import Heading from "../../../components/heading"
import AussichtManuskript from "../../dauerausstellung/06-hoelderlins-turmzimmer/aussicht-manuskript"

const AussichtGrid = (props) => {
  return (
    <Grid columns={[1, 2]} alignY="center">
      <Stack space={6}>
        <Heading level={5}>Aussicht.</Heading>
        <Poem size={[3, 3, 3, 4]}>
          <p>Der off’ne Tag ist Menschen hell mit Bildern,</p>
          <p>Wenn sich das Grün aus ebner Ferne zeiget,</p>
          <p>Noch eh’ des Abends Licht zur Dämmerung sich neiget,</p>
          <p>Und Schimmer sanft den Glanz des Tages mildern.</p>
          <p>Offt scheint die Innerheit der Welt umwölkt, verschlossen,</p>
          <p>Des Menschen Sinn von Zweifeln voll, verdrossen,</p>
          <p>Die prächtige Natur erheitert seine Tage,</p>
          <p>Und ferne steht des Zweifels dunkle Frage.</p>
          <br />
          <p>d. 24ten März 1871.</p>
          <br />
          <p>Mit Unterthänigkeit</p>
          <p>Scardanelli.</p>
        </Poem>
      </Stack>
      <AussichtManuskript />
    </Grid>
  )
}

export default AussichtGrid
