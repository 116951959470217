import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../../components/grid"
import Image from "../../../components/image"
import Stack from "../../../components/stack"
import Caption from "../../../components/caption"

const Brief = () => {
  const data = useStaticQuery(graphql`
    query {
      vorderseite: file(
        relativePath: { eq: "aus-dem-archiv-geholt/06/brief-vorderseite.png" }
      ) {
        ...mediumImage
      }
      rueckseite: file(
        relativePath: { eq: "aus-dem-archiv-geholt/06/brief-rueckseite.png" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Stack space={3}>
      <Grid columns={[1, 2]} space={3}>
        <Image
          image={data.vorderseite.childImageSharp.gatsbyImageData}
          alt="Ein handbeschriebenes altes Papier."
        />
        <Image
          image={data.rueckseite.childImageSharp.gatsbyImageData}
          alt="Ein handbeschriebenes altes Papier. Die Faltungen des Briefes sind noch deutlich zu sehen."
        />
      </Grid>
      <Caption>
        Abbildungen: Brief von Friedrich Hölderlin an die Schwester Heinrike
        Breunlin aus dem Hölderlin-Archiv der Württembergischen
        Landesbibliothek.
      </Caption>
    </Stack>
  )
}

export default Brief
