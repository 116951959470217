import React from "react"
import PropTypes from "prop-types"
import { darken } from "@theme-ui/color"

import Box from "./box"
import Icon from "./icon"
import LoadingSpinner from "./loading-spinner"

const PlayPauseButton = ({
  isPlaying,
  isLoading,
  color,
  isDisabled,
  onClick,
}) => {
  if (!isDisabled && isLoading) {
    return (
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: "100%",
          bg: "disabled",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner />
      </Box>
    )
  }

  return (
    <Box
      as="button"
      type="button"
      aria-label={isPlaying ? "Pausieren" : "Abspielen"}
      disabled={isDisabled}
      onClick={onClick}
      sx={{
        width: 12,
        height: 12,
        borderRadius: "100%",
        bg: isDisabled ? "disabled" : color,
        color: isDisabled ? "muted" : "black",
        cursor: isDisabled ? "default" : "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover, &:focus": {
          bg: isDisabled ? "disabled" : darken(color, 0.15),
        },
      }}
    >
      <Icon icon={isPlaying ? "pause" : "play"} />
    </Box>
  )
}

PlayPauseButton.propTypes = {
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  isPlaying: PropTypes.bool,
  onClick: PropTypes.func,
}

PlayPauseButton.defaultProps = {
  color: "primary",
}

export default PlayPauseButton
