import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"

import Caption from "../../../components/caption"
import Grid from "../../../components/grid"
import Image from "../../../components/image"
import Stack from "../../../components/stack"

const Letter = ({ page }) => {
  const data = useStaticQuery(graphql`
    query VerliebtLetter {
      brief1: file(
        relativePath: { eq: "aus-dem-archiv-geholt/04/brief-1.png" }
      ) {
        ...largeImage
      }
      brief2: file(
        relativePath: { eq: "aus-dem-archiv-geholt/04/brief-2.png" }
      ) {
        ...largeImage
      }
      brief3: file(
        relativePath: { eq: "aus-dem-archiv-geholt/04/brief-3.png" }
      ) {
        ...largeImage
      }
      brief4: file(
        relativePath: { eq: "aus-dem-archiv-geholt/04/brief-4.png" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      {page === 1 ? (
        <Grid columns={[1, 2]} space={0}>
          <Image
            image={data.brief1.childImageSharp.gatsbyImageData}
            alt="Eine weiß-gelbe Figur von einer Frau. Die feinen Lockenhaare sind zu einem Zopf hochgesteckt."
          />
          <Image
            image={data.brief2.childImageSharp.gatsbyImageData}
            alt="Eine weiß-gelbe Figur von einer Frau. Die feinen Lockenhaare sind zu einem Zopf hochgesteckt."
          />
        </Grid>
      ) : (
        <Grid columns={[1, 2]} space={0}>
          <Image
            image={data.brief3.childImageSharp.gatsbyImageData}
            alt="Eine weiß-gelbe Figur von einer Frau. Die feinen Lockenhaare sind zu einem Zopf hochgesteckt."
          />
          <Image
            image={data.brief4.childImageSharp.gatsbyImageData}
            alt="Eine weiß-gelbe Figur von einer Frau. Die feinen Lockenhaare sind zu einem Zopf hochgesteckt."
          />
        </Grid>
      )}
      <Caption>
        Brief von Susette Gontard an Friedrich Hölderlin aus dem
        Hölderlin-Archiv der Württembergischen Landesbibliothek.
      </Caption>
    </Stack>
  )
}

Letter.propTypes = {
  page: PropTypes.number,
}

Letter.defaultProps = {
  page: 1,
}

export default Letter
