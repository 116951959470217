import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../../../components/image"

const NurUnsereBesten = () => {
  const data = useStaticQuery(graphql`
    query {
      besten: file(
        relativePath: { eq: "aus-dem-archiv-geholt/06/nur-unsere-besten.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Image
      image={data.besten.childImageSharp.gatsbyImageData}
      caption="Via Lewandowsky: ›Nur unsere Besten‹. Fliegen aus dem Jahr 1966 auf Leinwand (2010)"
      attribution="© VG Bild-Kunst, Bonn / Foto: Thomas Bruns"
      alt="Ein quadratisches weißes Papier, auf dem viele kleine Fliegen aufgebracht sind."
    />
  )
}

export default NurUnsereBesten
