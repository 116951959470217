import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Feuerteufel = () => {
  const data = useStaticQuery(graphql`
    query Feuerteufel {
      feuerteufel: file(
        relativePath: { eq: "veranstaltungen/postkarte_feuerteufel.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 760, height: 525, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Der Hölderlinturm Tübingen präsentiert ab dem 20. September 2024 die neue Sonderausstellung mit dem Titel „Turm in Flammen! Die Fahndungsakte »Feuerteufel«“. 
        Mittelpunkt dieser True Crime-Ausstellung ist eine bisher unveröffentlichte Feuerpolizeiakte des Oberamts Tübingen zu einem bis heute ungeklärten Brandfall vom 14. Dezember 1875, 
        die nun der Öffentlichkeit zugänglich gemacht wird. Bei dem Feuer vor fast 150 Jahren wurde das Haus, in dem der Dichter Friedrich Hölderlin von 1807 bis 1843 gelebt hatte bis auf ein paar Mauerreste komplett zerstört.
      </Paragraph>
      <Paragraph>
        Wenige Monate später wurde es als Neubau in komplett veränderter Gestalt wiederaufgeführt - es entstand der berühmte Hölderlinturm, das Wahrzeichen Tübingens.
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
            Aus Platzgründen ist eine Anmeldung erforderlich:{" "}
            <ExternalLink href="https://app.cituro.com/booking/stadtmuseumtuebingen">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
        </Paragraph>
      </Stack>
      <Grid columns={2} space={"px"}>
        <Image
          image={data.feuerteufel.childImageSharp.gatsbyImageData}
          alt="Postkarte" 
        />
      </Grid>
    </Stack>
    
  )
}

export default Feuerteufel