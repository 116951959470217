/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import React from "react"

import Box from "./box"
import Divider from "./divider"

const Stack = (props) => {
  const {
    children,
    as: element,
    dividers,
    dividerColor,
    dividerSize,
    space,
    ...restProps
  } = props

  const isList = element === "ol" || element === "ul"
  const spaceFromProps = space
  const negativeSpace = Array.isArray(spaceFromProps)
    ? spaceFromProps.map((value) => (value !== 0 ? -value : value))
    : space * -1

  return (
    <Box
      as={element}
      {...restProps}
      display="flex"
      flexDirection="column"
      listStyleType={isList ? "none" : null}
      mt={isList ? "0px" : null}
      mb={negativeSpace}
      pl={isList ? "0px" : null}
    >
      {/* We need to turn children into an array to allow for if/else statements within stacks */}
      {React.Children.toArray(children).map((child, i) => {
        return (
          <Box as={isList ? "li" : "div"} key={i} pb={!dividers ? space : null}>
            {i === 0 && dividers ? (
              <Box pb={space}>
                <Divider color={dividerColor} size={dividerSize} />
              </Box>
            ) : null}
            {child}
            {dividers ? (
              <Box py={space}>
                <Divider color={dividerColor} size={dividerSize} />
              </Box>
            ) : null}
          </Box>
        )
      })}
    </Box>
  )
}

Stack.propTypes = {
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  dividerColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  dividerSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  dividers: PropTypes.bool,
}

Stack.defaultProps = {
  space: [6, 12],
  dividerSize: 1,
  dividers: false,
}

export default Stack
