import React from "react"

import Accent from "../../../components/accent"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import NoBreak from "../../../components/no-break"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import Trivia from "../../../components/trivia"
import MetricBox from "../../../components/metric-box"

const HoelderlinsMetrik = (props) => {
  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Hölderlins Metrik
          </Heading>
          <Paragraph>
            Insbesondere die Versmaße sind es, die Hölderlins Gedichten einen
            ganz einzigartigen Sound verleihen. Nicht ohne Grund wird der
            Rhythmus eines Gedichts in Vers
            <Text as="em" italic>
              füßen
            </Text>{" "}
            (griechisch πούς{" "}
            <Text as="em" italic>
              poús
            </Text>
            , lateinisch{" "}
            <Text as="em" italic>
              pes
            </Text>
            ) gemessen.
          </Paragraph>
          <Paragraph>
            Der{" "}
            <NoBreak as="strong" sans bold>
              Trochäus
            </NoBreak>
            , ein Versfuß aus einer betonten{" "}
            <Text as="span" sans color="primary">
              —
            </Text>{" "}
            und einer unbetonten Silbe{" "}
            <Text as="span" sans color="primary">
              ◡
            </Text>
            , heißt übersetzt sogar »laufend«, »schnell«.
          </Paragraph>
          <Paragraph>
            Beim Skandieren, dem rhythmischen Abschreiten der Gedichte im Tanz,
            unterschied man in der Antike zwischen Aris (der Hebung des Fußes)
            und Thesis (der Senkung). In der griechischen Sprache wurden die
            Silben nach Längen und Kürzen gemessen. Im Deutschen unterscheidet
            man dagegen zwischen betonten und unbetonten Silben. Aus der
            Kombination aus betonten und unbetonten Silben ergeben sich
            verschiedene Versfüße.
          </Paragraph>
        </Stack>
      </Constrain>
      <Grid columns={[1, 1, 2]} alignX="center">
        <MetricBox
          title="Trochäus"
          greek="τροχαῖος"
          greekNormalized="trochaios"
          metric="—◡"
        >
          <span>
            <Accent>Ro</Accent>sen, <Accent>Schwä</Accent>ne
          </span>
        </MetricBox>
        <MetricBox
          title="Jambus"
          greek="ἴαμβος"
          greekNormalized="ïambos"
          metric="◡—"
        >
          <span>
            Nat<Accent>ur</Accent>
          </span>
        </MetricBox>
        <MetricBox
          title="Daktylus"
          greek="δάκτυλος"
          greekNormalized="dáktylos"
          metric="—◡◡"
        >
          <span>
            <Accent>Hei</Accent>delberg, <Accent>Himm</Accent>lisches
          </span>
        </MetricBox>
        <MetricBox
          title="Anapäst"
          greek="ἀνάπαιστος"
          greekNormalized="anápaistos"
          metric="◡◡—"
        >
          <span>
            Poe<Accent>sie</Accent>
          </span>
        </MetricBox>
      </Grid>
      <Constrain>
        <Stack>
          <Paragraph>
            In der Antike unterschied man nach zwei-, drei- und viergliedrig
            zusammengesetzten Versfüßen und hatte für jeden von ihnen einen
            Namen. Die griechische Sprache und Literatur der Antike ermöglichte
            dabei einen weit größeren kombinatorischen Reichtum als die deutsche
            Sprache. <NoBreak>Hölderlin</NoBreak> aber versuchte sich auch in
            diesen komplexen griechischen Versmaßen, wie eine metrische Analyse
            seines das Gedichts ›Hälfte des Lebens‹ beweist.
          </Paragraph>
          <Trivia>
            <span>
              Der{" "}
              <NoBreak as="strong" sans bold>
                Daktylus
              </NoBreak>{" "}
              (altgriechisch δάκτυλος – »Finger«), bestehend aus einer langen
              und zwei kurzen Silben (im Deutschen: einer betonten und zwei
              unbetonten), ist abgeleitet von den drei Gliedern eines Fingers: —
              ◡ ◡
            </span>
          </Trivia>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default HoelderlinsMetrik
