import React from "react"
import Modal from "./modal"
import ReadMoreButton from "./read-more-button"
import Container from "./container"
import Stack from "./stack"

const ReadMore = (props) => {
  const { content, label, children, ...restProps } = props

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal(e) {
    e.preventDefault()
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <ReadMoreButton onClick={openModal} {...restProps}>
        {children}
      </ReadMoreButton>
      {content && (
        <Modal
          isOpen={modalIsOpen}
          onClose={closeModal}
          contentLabel={`modal${label}`}
        >
          <Container maxWidth="5xl">
            <Stack space={12}>{content}</Stack>
          </Container>
        </Modal>
      )}
    </>
  )
}

export default ReadMore
