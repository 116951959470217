import PropTypes from "prop-types"
import React from "react"

import Box from "./box"

export const ColumnsContext = React.createContext()

const Columns = (props) => {
  const { children, collapse, reverse, alignX, alignY, space, ...restProps } =
    props

  const spaceFromProps = space
  const negativeSpace = Array.isArray(spaceFromProps)
    ? spaceFromProps.map((value) => (value !== 0 ? -value : value))
    : space * -1

  const collapseFromProps = collapse
  const resolveDisplay = Array.isArray(collapseFromProps)
    ? collapseFromProps.map((value) => (value === true ? "block" : "flex"))
    : collapseFromProps === true
    ? "block"
    : "flex"

  const reverseFromProps = reverse
  const resolveReverse = Array.isArray(reverseFromProps)
    ? reverseFromProps.map((value) => (value === true ? "row-reverse" : "row"))
    : reverseFromProps === true
    ? "row-reverse"
    : "row"

  return (
    <Box
      display={resolveDisplay}
      flexDirection={resolveReverse}
      alignX={alignX}
      alignY={alignY}
      ml={negativeSpace}
      mt={negativeSpace}
      {...restProps}
    >
      <ColumnsContext.Provider value={space}>
        {children}
      </ColumnsContext.Provider>
    </Box>
  )
}

Columns.propTypes = {
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  collapse: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  reverse: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
}

Columns.defaultProps = {
  space: [3, 6],
  collapse: false,
  reverse: false,
}

export default Columns
