import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import Stack from "../../../components/stack"

const EinfuehrungTurmzimmer = (props) => {
  const data = useStaticQuery(graphql`
    query EinfuehrungTurmzimmerQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/cover.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Paragraph dropcap={true}>
        Ein Tischchen, ein Bett, ein Ofen und ein »an der Türe stehender Kasten«
        sollen in Hölderlins Turmzimmer gestanden haben. Und das obwohl es
        damals noch etwa 4 m2 kleiner war als heute. Es war nicht rund, sondern
        achteckig geschnitten und statt seiner heutigen 3, hatte es 5 Fenster.
      </Paragraph>
      <Image
        image={data.cover.childImageSharp.gatsbyImageData}
        alt="Hölderlins Turmzimmer mit Schreibtisch in der Mitte und Manuskript an der Wand"
      />
      <Constrain>
        <Stack>
          <Paragraph>
            Von seinem Zimmer aus konnte Hölderlin – so sein Kostgeber – das
            »ganze Näkerthal« übersehen; und Waiblinger bewunderte »manche
            schöne Bilder«, die der Dichter »frischweg aus der Natur hohlte,
            indem er von seinem Fenster aus den Frühling kommen und gehen sah«.
          </Paragraph>
          <Paragraph>
            Der Blick konnte damals weit streifen. Die Platanen gegenüber waren
            erst 1828 gepflanzt worden. Hölderlin lebte da schon zwei Jahrzehnte
            hier. Bis zu seinem Tod 1843 konnte er auch beobachten, wie sich die
            Stadt auf der anderen Seite des Flusses ausdehnte.
          </Paragraph>
          <Paragraph>
            Schweiften Hölderlins frühere Gedichte meist weit in die Ferne –
            nach Italien, Frankreich und Griechenland –, so verweilen die
            Gedichte, die er im Turm verfasste, an Ort und Stelle. Die Aussicht
            aus dem Fenster und der sich darin abzeichnende Wechsel der
            Jahreszeiten wurden ihm zum Motiv, das er ein aufs andre Mal
            umkreiste.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default EinfuehrungTurmzimmer
