import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"

const EinfuehrungWirkung = (props) => {
  const data = useStaticQuery(graphql`
    query RaumIntroWirkungQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/cover.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack as="section">
      <Paragraph dropcap={true}>
        Die Geschichte des Hölderlinturms reicht um einiges über die Jahre
        hinaus, die sein Namensgeber hier verbrachte. Er diente als Färberei,
        Tuchmacherei, Brauerei, Schreinerwerkstatt, Dichterhaus und Badeanstalt.
        Seine bis heute anhaltende Anziehungskraft verdankt er jedoch dem
        Dichter und seiner beeindruckenden Wirkungsgeschichte.
      </Paragraph>
      <Image
        image={data.cover.childImageSharp.gatsbyImageData}
        alt="Bild des zweiten Raumes der Dauerausstellung: An den Wänden und in den Vitrinen verschiedene Kunstwerke, die sich auf Hölderlin beziehen"
        attribution="Gudrun de Maddalena"
      />
      <Constrain>
        <Stack space={24}>
          <Stack space={3}>
            <Heading as="h2" level={3}>
              Abgebrannt
            </Heading>
            <Paragraph>
              30 Jahre nach Hölderlins Tod ist das Gebäude, in dem er die zweite
              Hälfte seines Lebens verbrachte, niedergebrannt. Die schwäbischen
              Romantiker um Ludwig Uhland, Gustav Schwab und Eduard Mörike, die
              sich Hölderlins Werk bereits zu dessen Lebzeiten annahmen, haben
              das Haus aber noch so kennengelernt, wie es der Dichter selbst
              erlebt hat. In leicht veränderter Form ist es 1876, ein Jahr nach
              seiner Zerstörung, wieder aufgebaut worden.
            </Paragraph>
          </Stack>
          <Stack space={3}>
            <Heading as="h2" level={3}>
              Hölderlins Thurm
            </Heading>
            <Paragraph>
              Dass der Ort erst mit dem Neubau seinen Namen erhielt und seither
              ›Hölderlins Thurm‹ heißt, bekundet das wachsende Interesse am Werk
              und der letzten Lebensstation des Dichters. Seine Rezeption, die
              zunächst weitgehend auf Schwaben begrenzt blieb, nahm um 1900
              Fahrt auf – besonders durch den Kreis um Stefan George und die
              Dichter des Expressionismus.
            </Paragraph>
          </Stack>
          <Stack space={3}>
            <Heading as="h2" level={3}>
              Ort der Inspiration
            </Heading>
            <Paragraph>
              Bis heute findet sich kaum ein Schriftsteller, der Literatur,
              bildende Kunst und Musik derart herausgefordert hat wie Hölderlin.
              Für viele der Künstlerinnen und Künstler, die Tübingen selbst
              besuchten, vom Dichter Paul Celan über den Maler Alfred Hrdlicka
              bis hin zum Komponisten Luigi Nono, wurde der Turm zum Ort der
              Inspiration.
            </Paragraph>
          </Stack>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default EinfuehrungWirkung
