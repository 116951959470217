import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const FuehrungenDauerausstellung = () => {
  const data = useStaticQuery(graphql`
    query {
      detail: file(relativePath: { eq: "ausstellungen/detail.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 380, layout: CONSTRAINED)
        }
      }
      fuegungen: file(
        relativePath: { eq: "dauerausstellung/07-harte-fuegungen/cover.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 380, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Jeden 1. und 3. Sonntag im Monat bieten wir eine öffentliche Führung
        durch die{" "}
        <TextLink to="/ausstellungen/dauerausstellung">
          Dauerausstellung
        </TextLink>{" "}
        an. Der 60-minütige Rundgang führt durch alle Räume der Ausstellung und
        vermittelt anhand überlieferter Manuskripte, Dokumente und
        Besucherberichte Hölderlins Lebensalltag und poetisches Schaffen während
        seiner letzten 36 Jahre im Turm.
      </Paragraph>
      <Grid columns={2} space={"px"}>
        <Image
          image={data.detail.childImageSharp.gatsbyImageData}
          alt="Bild des Raumes Tübinger Hoffnungen im ersten Stock des Turms. An der Wand eine graue Vitrine mit historischer Stadtansicht"
          attribution="David Franck"
        />
        <Image
          image={data.fuegungen.childImageSharp.gatsbyImageData}
          alt="Raumansicht mit neun dunklen säulenartigen Vitrinen an der Wand und einer Vitrine mit Glashaube in der Mitte"
          attribution="David Franck"
        />
      </Grid>
      <Paragraph>
        Auch Hölderlins Studienjahre am Evangelischen Stift, die dort geknüpften
        Freundschaften und ersten Gedichtveröffentlichungen sowie die außerhalb
        Tübingens entstandenen Werke werden an anschaulichen Zeugnissen
        thematisiert. Darüber hinaus verfolgt der Rundgang Hölderlins große
        Wirkung auf die Literatur, Musik und Kunst und spürt den stilistischen
        Eigenheiten seiner Gedichte nach.
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
          Die Anmeldung erfolgt an der Museumskasse. Eine telefonische
          Voranmeldung ist während der{" "}
          <TextLink to="/kontakt#oeffnungszeiten">
            Museumsöffnungszeiten
          </TextLink>{" "}
          unter 07071 2041862 möglich.
        </Paragraph>
      </Stack>
    </Stack>
  )
}

export default FuehrungenDauerausstellung
