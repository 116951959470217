import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Paragraph from "../../../components/paragraph"
import Blockquote from "../../../components/blockquote"
import Poem from "../../../components/poem"
import Columns from "../../../components/columns"
import Column from "../../../components/column"
import ImageHotspots from "../../../components/image-hotspots"
import ImageHotspot from "../../../components/image-hotspot"
import Constrain from "../../../components/constrain"
import Heading from "../../../components/heading"
import ReadMore from "../../../components/read-more"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import QuotePrimary from "../../../components/quote-primary"

const Celan = (props) => {
  const data = useStaticQuery(graphql`
    query WirkungCelanQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/cover.jpg"
        }
      ) {
        ...largeImage
      }
      jaenner: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/paul-celan-jaenner.png"
        }
      ) {
        ...largeImage
      }

      datum: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/celan-1-datum.jpg"
        }
      ) {
        ...mediumImage
      }
      rhein: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/celan-7-rhein.jpg"
        }
      ) {
        ...mediumImage
      }
      hoelderlintuerme: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/celan-4-hoelderlintuerme.jpg"
        }
      ) {
        ...mediumImage
      }
      zeichen: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/celan-3-zeichen.jpg"
        }
      ) {
        ...mediumImage
      }
      kind: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/celan-6-kind.jpg"
        }
      ) {
        ...mediumImage
      }
      pallaksch: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/celan-2-pallaksch.jpg"
        }
      ) {
        ...mediumImage
      }
      handschrift: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/celan-5-handschrift.jpg"
        }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Stack as="section" space={[12, 32]}>
      <Stack>
        <Constrain align="left">
          <Stack space={6}>
            <Heading as="h2" level={2}>
              Paul Celans Gedicht ›Tübingen Jänner‹
            </Heading>
            <Paragraph>
              Eine Erinnerung an »schwimmende Hölderlintürme« hält eines der
              wichtigsten Zeugnisse der literarischen Hölderlin-Rezeption fest:
              Paul Celans Gedicht ›Tübingen, Jänner‹, das unmittelbar auf einen
              Besuch des Dichters in Tübingen entstanden ist.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain>
          <ImageHotspots
            caption="Paul Celans Gedichtmanuskript ›Tübingen, Jänner‹ aus dem Deutschen Literaturarchiv Marbach"
            image={
              <Image
                image={data.jaenner.childImageSharp.gatsbyImageData}
                alt="Ein DIN A4 Blatt, auf dem Paul Celan ›Tübingen, Jänner‹ mit einer Schreibmaschine verfasst und von Hand ergänzt hat"
              />
            }
          >
            <ImageHotspot x={25} y={57} name="datum">
              <Heading level={3}>Ort und Zeit der Entstehung</Heading>
              <Columns space={12} collapse={[true, true, false]}>
                <Column width={["full", "60%", "40%"]}>
                  <Image
                    image={data.datum.childImageSharp.gatsbyImageData}
                    alt="Vergößerter Ausschnitt des Manuskripts: Paris, 29. 1. 1961"
                  />
                </Column>
                <Column>
                  <Stack>
                    <Paragraph>
                      Schon mit dem Titel verortet Celan sein Gedicht sowohl
                      räumlich als auch zeitlich: ›Tübingen, Jänner 1961‹. Eine
                      Referenz, die er nach Abschluss des 1. Entwurfs korrigiert
                      und konkretisiert: Nicht in Tübingen, sondern in Paris,
                      und genauer, am 29. Jänner 1961, ist das Gedicht
                      entstanden. Dennoch: Sein Ursprung liegt in Tübingen und
                      an dieser Referenz hält Celan mit dem Titel fest.
                    </Paragraph>
                    <Paragraph>
                      Am 28.1.1961 war Celan, wie aus seinem Tagebuch und
                      Taschenkalender hervorgeht, mit dem Zug von Paris nach
                      Tübingen gereist. Die Plagiatsvorwürfe, die von Claire
                      Goll gegen ihn erhoben wurden, führten ihn nach Tübingen,
                      zu einem Gespräch mit dem Schriftsteller und
                      Literaturkritiker{" "}
                      <ReadMore
                        content={
                          <Constrain>
                            <Stack>
                              <Paragraph>
                                Walter Jens (1923–2013) war Altphilologe,
                                Kritiker, Übersetzer und Schriftsteller. 1956
                                wurde er für eine Professur in Klassischer
                                Philologie nach Tübingen berufen. 1963 gründete
                                er dort den Lehrstuhl für Allgemeine Rhetorik –
                                den ersten dieser Art in Deutschland seit 1829.
                              </Paragraph>
                              <Paragraph>
                                Daneben schrieb er unter dem Pseudonym »Momos«
                                für ›Die Zeit‹ und galt als gefürchteter
                                Kritiker in der Gruppe 47.
                              </Paragraph>
                            </Stack>
                          </Constrain>
                        }
                      >
                        Walter Jens
                      </ReadMore>
                      . Dabei wird Celan, dessen gesamtes Schaffen immense
                      Bezüge zu Hölderlin aufweist, sicherlich auch am
                      Hölderlinturm Station gemacht haben.
                    </Paragraph>
                    <Paragraph>
                      Ganz unmittelbar darauf entstand, noch am Tag der Rückkehr
                      aus Tübingen, der erste Entwurf des Gedichts. Sowohl die
                      Jahreszahl im Titel als auch das darunter notierte
                      Entstehungsdatum fielen später in der Überarbeitung für
                      den Druck heraus.
                    </Paragraph>
                  </Stack>
                </Column>
              </Columns>
            </ImageHotspot>

            <ImageHotspot x={21} y={27} name="rhein">
              <Heading level={3}>
                »ein Rätsel / ist / R(h)ein- / entsprungenes«
              </Heading>
              <Columns space={12} collapse={[true, true, false]}>
                <Column width={["full", "60%", "40%"]}>
                  <Image
                    image={data.rhein.childImageSharp.gatsbyImageData}
                    alt="Vergößerter Ausschnitt des Manuskripts: „ein Rätsel ist Reinentsprungenes“"
                  />
                </Column>
                <Column>
                  <Stack>
                    <Paragraph>
                      Mit diesen Worten beginnt die 4. Strophe von Hölderlins
                      Hymne ›Der Rhein‹. Während er sich im Titel und weiteren
                      Verlauf des Gedichts an die orthographisch korrekte
                      Bezeichnung des Flusses (Der »Rhein«) hält, lässt
                      Hölderlin mit dem Wort »Reinentsprungenes« eine wohl ganz
                      bewusst angelegte Mehrdeutigkeit entstehen, die sowohl auf
                      den Ursprung des Rheins, als auch auf seine Reinheit
                      anspielt.
                    </Paragraph>
                    <Paragraph>
                      Durch das Aufbrechen der zitierten Passage im Enjambement
                      hebt Celan die darin enthaltene Mehrdeutigkeit in seinem
                      Gedicht sogar noch stärker hervor.
                    </Paragraph>
                    <QuotePrimary author="Friedrich Hölderlin: Der Rhein">
                      <span>Ein Räthsel ist Reinentsprungenes. Auch</span>
                      <span>Der Gesang kaum darf es enthüllen</span>
                    </QuotePrimary>
                  </Stack>
                </Column>
              </Columns>
            </ImageHotspot>
            <ImageHotspot x={34} y={31} name="hoelderlintuerme">
              <Heading level={3}>Schwimmende Hölderlintürme</Heading>
              <Columns space={12} collapse={[true, true, false]}>
                <Column width={["full", "60%", "40%"]}>
                  <Image
                    image={
                      data.hoelderlintuerme.childImageSharp.gatsbyImageData
                    }
                    alt="Vergößerter Ausschnitt des Manuskripts: Erinnerung an schwimmende Hölderlintürme"
                  />
                </Column>
                <Column>
                  <Stack>
                    <Paragraph>
                      Schon Celans erster Besuch in Tübingen im Jahr 1955 war
                      ein Besuch bei Hölderlin. Schon damals waren seine
                      Lektüren, Gespräche und Gedichte unverkennbar von
                      Hölderlin geprägt. Von Hölderlins Grab auf dem Tübinger
                      Stadtfriedhof ging es zum Turm am Neckar und von dort nach
                      Bebenhausen, wo zu dieser Zeit das Hölderlin-Archiv
                      untergebracht war.
                    </Paragraph>
                    <Paragraph>
                      Das Bild der schwimmenden Hölderlintürme wird Celan beim
                      Anblick der Spiegelungen des Turms auf dem Neckar gekommen
                      sein. Der Ort muss für ihn eine starke Bedeutung und
                      Inspirationskraft besessen haben.
                    </Paragraph>
                    <Paragraph>
                      Auch sein letzter Besuch in Tübingen anlässlich Hölderlins
                      200. Geburtstag, nur wenige Wochen vor seinem Suizid im
                      April 1970, führte Celan in den Hölderlinturm, wo er sich
                      als Besucher in das Gästebuch eintrug.
                    </Paragraph>
                  </Stack>
                </Column>
              </Columns>
            </ImageHotspot>
            <ImageHotspot x={16} y={40} name="zeichen">
              <Heading level={3}>
                Arbeit an der Architektur des Gedichts
              </Heading>
              <Columns space={12} collapse={[true, true, false]}>
                <Column width={["full", "60%", "40%"]}>
                  <Image
                    image={data.zeichen.childImageSharp.gatsbyImageData}
                    alt="Manuskript mit handschriflichen Zeichen am linken Rand"
                  />
                </Column>
                <Column>
                  <Paragraph>
                    Mit diesen Zeichen markiert Celan die Leerzeilen, die er
                    nachträglich einfügt, um die Verse umzustrukturieren und die
                    drei mittleren Verse zu separieren.
                  </Paragraph>
                </Column>
              </Columns>
            </ImageHotspot>

            <ImageHotspot x={36} y={39} name="kind">
              <Heading level={3}>Vom Kind zum Menschen</Heading>
              <Columns space={12} collapse={[true, true, false]}>
                <Column width={["full", "60%", "40%"]}>
                  <Image
                    image={data.kind.childImageSharp.gatsbyImageData}
                    alt="Kind"
                  />
                </Column>
                <Column>
                  <Paragraph>
                    In der Überarbeitung für den Druck ersetzt Celan das Wort
                    »Kind« durch das allgemeiner gefasste »Mensch«.
                  </Paragraph>
                </Column>
              </Columns>
            </ImageHotspot>

            <ImageHotspot x={40} y={53} name="pallaksch">
              <Heading level={3}>Pallaksch, Pallaksch</Heading>
              <Columns space={12} collapse={[true, true, false]}>
                <Column width={["full", "60%", "40%"]}>
                  <Image
                    image={data.pallaksch.childImageSharp.gatsbyImageData}
                    alt="Manuskript mit handschriflicher Ergänzung: Pallaksch, Pallaksch"
                  />
                </Column>
                <Column>
                  <Paragraph>
                    Das Wort »Pallaksch« soll Hölderlin gegenüber seinen
                    Besuchern im Turm im Mund geführt haben. Ein Neologismus,
                    von dem Christoph Theodor Schwab vermutete, er könne sowohl
                    »ja«, als auch »nein« bedeuten. Oder, wie Johann Kreuzer es
                    formuliert: Ein Ausdruck, den er verwendete, wenn er es leid
                    war, auf Fragen mit einem eindeutigen »ja« oder »nein« zu
                    antworten.
                  </Paragraph>
                </Column>
              </Columns>
            </ImageHotspot>

            <ImageHotspot x={80} y={77} name="strophe">
              <Heading level={3}>Noch einmal</Heading>
              <Columns space={12} collapse={[true, true, false]}>
                <Column width={["full", "60%", "40%"]}>
                  <Image
                    image={data.handschrift.childImageSharp.gatsbyImageData}
                    alt="Bild der handschriftlichen Anmerkungen Celans zur 2. Strophe"
                  />
                </Column>
                <Column>
                  <Stack>
                    <Paragraph>
                      Während er die 1. Strophe kaum mehr verändert, setzt Celan
                      von Hand noch einmal neu zur 2. Strophe an. Bis auf den
                      Zusatz
                    </Paragraph>
                    <Blockquote as="blockquote">
                      <Paragraph as="span">
                        »immer-, immer-/ zu. / (zuzu.)«{" "}
                      </Paragraph>
                    </Blockquote>
                    <Paragraph>
                      verändert er die Strophe zunächst jedoch nur marginal.
                    </Paragraph>
                    <Poem italic>
                      <span>Käme ein Kind,</span>
                      <span>ein Kind zur Welt, heute,</span>
                      <span>und trüge es,</span>
                      <span>und trüg es den</span>
                      <span>Lichtbart</span>
                      <span>des</span>
                      <span>Patriarchen:</span>
                      <span>es dürfte, spräch es von dieser</span>
                      <span>Zeit,</span>
                      <span>nur lallen und lallen,</span>
                      <span>immer, immer-</span>
                      <span>zu.</span>
                      <span>(zuzu.)</span>
                    </Poem>
                  </Stack>
                </Column>
              </Columns>
            </ImageHotspot>
          </ImageHotspots>
        </Constrain>
        <Constrain>
          <QuotePrimary author="Paul Celan: Tübingen, Jänner" size={[3, 3, 4]}>
            <span>Zur Blindheit über-</span>
            <span>redete Augen.</span>
            <span>Ihre – „ein</span>
            <span>Rätsel ist Rein-</span>
            <span>entsprungenes“ –, ihre</span>
            <span>Erinnerung an</span>
            <span>schwimmende Hölderlintürme, möwen-</span>
            <span>umschwirrt.</span>
            <span>
              <br />
            </span>
            <span>Besuche ertrunkener Schreiner bei</span>
            <span>diesen</span>
            <span>tauchenden Worten:</span>
            <span>
              <br />
            </span>
            <span>Käme</span>
            <span>käme ein Mensch,</span>
            <span>käme ein Mensch zur Welt, heute, mit</span>
            <span>dem Lichtbart der</span>
            <span>Patriarchen: er dürfte,</span>
            <span>spräche er von dieser</span>
            <span>Zeit, er</span>
            <span>dürfte</span>
            <span>nur lallen und lallen,</span>
            <span>immer, immer-</span>
            <span>zuzu.</span>
            <span>
              <br />
            </span>
            <span>(„Pallaksch. Pallaksch“)</span>
          </QuotePrimary>
        </Constrain>
      </Stack>
      <Constrain>
        <Stack>
          <Heading level={5}>Mehr zu Paul Celans Tübingen, ›Jänner‹</Heading>
          <Stack space={3}>
            <Text size={3}>
              <Heading as="span">Axel Gellhaus</Heading>:{" "}
              <TextLink href="https://www.dla-marbach.de/shop/shop-einzelansicht/?tt_products%5BbackPID%5D=365&tt_products%5Bproduct%5D=126&cHash=759e104a4f2707b25d4f027ea50f6ae2">
                Erinnerung an schwimmende Hölderlintürme. Paul Celan ›Tübingen,
                Jänner‹
              </TextLink>
            </Text>
            <Text color="whisper">Marbach a.N. 2001 (Spuren 24)</Text>
          </Stack>
          <Stack space={3}>
            <Text size={3}>
              <Heading as="span">Barbara Wiedemann</Heading>:{" "}
              <Text as="span">
                »Ein Faible für Tübingen«. Paul Celan in Württemberg –
                Deutschland und Paul Celan
              </Text>
            </Text>
            <Text color="whisper">Tübingen 2013</Text>
          </Stack>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default Celan
