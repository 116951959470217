import React from "react"
import PropTypes from "prop-types"
import { alpha } from "@theme-ui/color"

import Box from "./box"
import Heading from "./heading"
import Poem from "./poem"
import Stack from "./stack"
import backgroundMetrik from "../images/bg-metrik.jpg"

const PoemMetric = (props) => {
  const { children, size, caption, player, title, subtitle, ...restProps } =
    props

  return (
    <Stack space={3}>
      <Box
        sx={{
          backgroundImage: `url("${backgroundMetrik}")`,
          display: "flex",
          height: "100%",
          flexDirection: "column",
          borderRadius: "lg",
          overflow: "hidden",
        }}
      >
        <Box
          p={[6, 6, 12]}
          height="100%"
          bg={alpha("muted", 0.95)}
          {...restProps}
        >
          <Stack>
            <Stack space={6}>
              {subtitle && (
                <Heading as="span" color="whisper">
                  {subtitle}
                </Heading>
              )}
              {title && (
                <Heading as="span" level={4}>
                  {title}
                </Heading>
              )}
              {children && (
                <Poem space={3} size={size}>
                  {children}
                </Poem>
              )}
            </Stack>
          </Stack>
        </Box>
      </Box>
      {player}
    </Stack>
  )
}

PoemMetric.propTypes = {
  children: PropTypes.array,
  player: PropTypes.node,
  caption: PropTypes.node,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

PoemMetric.defaultProps = {
  subtitle: "Rhythmus & Versmaß",
}

export default PoemMetric
