import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Denkmaltag = () => {
  const data = useStaticQuery(graphql`
    query Denkmaltag {
      denkmaltag: file(
        relativePath: { eq: "veranstaltungen/denkmal_header.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1000, height: 270, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Grid columns={1} space={"px"}>
        <Image
          image={data.denkmaltag.childImageSharp.gatsbyImageData}
          alt="Header Tag des offenen Denkmals" 
        />
      </Grid>
      <Paragraph>
        Der Hölderlinturm ist Tübingens Wahrzeichen und zählt zu den bedeutendsten Erinnerungsorten der Weltliteratur. 
        Jedoch ist nur wenigen Besucher_innen bewusst, dass das Haus 1875 in Folge von Brandstiftung fast komplett abgebrannt ist – 
        nur die mittelalterlichen Fundamente der Wehranlage aus dem 15. Jahrhundert blieben bestehen. Der Name »Hölderlins Thurm« 
        wiederum heftete sich erst nach dem Wiederaufbau an den turmartigen Vorbau des Gebäudes. In der Führung, 
        passend zum diesjährigen Motto des Tags des Offenen Denkmals, wird die Entwicklung zum Kulturdenkmal ebenso beleuchtet wie die Frage, 
        was am Hölderlinturm authentisch ist. Auch gibt es die seltene Gelegenheit die Turmspitze von innen zu sehen, 
        und es gibt eine kleine Vorschau zur Sonderausstellung »Turm in Flammen! Die Fahndungsakte Feuerteufel«.
      </Paragraph>
    </Stack>
  )
}

export default Denkmaltag