import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Blockquote from "../../../components/blockquote"
import Box from "../../../components/box"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import ImageHotspot from "../../../components/image-hotspot"
import ImageHotspots from "../../../components/image-hotspots"
import Poem from "../../../components/poem"
import QuotePrimary from "../../../components/quote-primary"
import ReadMore from "../../../components/read-more"
import ReadMoreBox from "../../../components/read-more-box"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"

const Bundesbuch = (props) => {
  const data = useStaticQuery(graphql`
    query HoffnungenBundesbuchQuery {
      bundesbuch: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/bundesbuch-vitrine.jpg"
        }
      ) {
        ...largeImage
      }
      magenau: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/magenau.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      neuffer: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/neuffer.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      hoelderlin: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/hoelderlin.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      bundesbuch01: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/bundesbuch-01.png"
        }
      ) {
        ...largeImage
      }
      bundesbuch02: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/bundesbuch-02.png"
        }
      ) {
        ...largeImage
      }
      bundesbuch03: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/bundesbuch-03.png"
        }
      ) {
        ...largeImage
      }
      bundesbuch04: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/bundesbuch-04.png"
        }
      ) {
        ...largeImage
      }
      schlafrock: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/dichter-im-schlafrock.png"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack space={[12, 24]}>
      <Stack>
        <Constrain>
          <Stack>
            <Heading as="h2" level={2}>
              Eine Seele in drei Leibern
            </Heading>
            <Paragraph>
              Im März 1790 gründete Hölderlin mit seinem »Herzensbruder« Neuffer
              und mit Magenau, den er schon aus der zuvor besuchten
              Klosterschule Maulbronn kannte, den Aldermannsbund – benannt nach
              jener geistigen Elite, der sein Vorbild Klopstock in der
              ›Deutschen Gelehrten-Republik‹ (1774) eine besondere
              Entscheidungsgewalt gab.
            </Paragraph>
          </Stack>
        </Constrain>
        <Image
          image={data.bundesbuch.childImageSharp.gatsbyImageData}
          alt="Dunkle Tischvitrine mit aufgeschlagenem Buch"
          caption="›Bundesbuch der Aldermänner‹ aus der Württembergischen Landesbibliothek Stuttgart "
        />
        <Constrain>
          <Stack>
            <Paragraph>
              Das eigens dafür angelegte, 346 Seiten starke ›Bundesbuch‹ hält
              jene Gedichte fest, mit denen die drei{" "}
              <ReadMore
                content={
                  <Constrain>
                    <Stack>
                      <Paragraph>
                        An eines dieser Zusammentreffen erinnert sich Magenau
                        noch Jahre später:
                      </Paragraph>
                      <Blockquote as="blockquote">
                        <Paragraph as="span">
                          »Eines solcher Gesellschäftchen verlegten wir an dem
                          heitersten Tage in den Garten des LammWirthes. [...]
                          Wir sangen alle Lieder der Freude nach der Reihe
                          durch. Auf die Bole Punsch hatten wir Schillers Lied
                          an die Frewude aufgespart. Ich ging sie zu hohlen.
                          Neuffer war eingeschlafen, da ich zurück kam, und
                          Hölderlin stand in einer Ecke und rauchte. Dampfend
                          stand die Bole auf dem Tische und nun sollte das Lied
                          beginnen, aber Hölderlin begehrte, dass wir erst an
                          der kastalischen Quelle uns von allen unsern Sünden
                          reinigen sollten. Nächst dem Garten floss der
                          sogenannte Philosophen-Brunnen, das war Hölderlins
                          kastalischer Quell; Wir gingen hin durch den Garten,
                          und wuschen das Gesicht und die Hände; Feierlich trat
                          Neuffer einher. Dies Lied von Schiller, sagte
                          Hölderlin, darf kein Unreiner singen! Nun sangen wir;
                          bei der Strophe ‚dieses Glas dem guten Geist‘ traten
                          helle klare Tränen in Hölderlins Auge, voll Glut hob
                          er den Becher zum Fenster hinaus gen Himmel, und
                          brüllte »dises Glas dem guten Geist« ins Freie, dass
                          das ganze Nekkartal widerscholl«
                        </Paragraph>
                      </Blockquote>
                    </Stack>
                  </Constrain>
                }
              >
                im wöchentlichen Ritual ihre Freundschaft feierten
              </ReadMore>
              . Stets war ein Thema vorgegeben, zu dem jeder ein Gedicht
              verfasste. Die regelmäßigen Einträge im Bundesbuch brechen
              allerdings bereits nach drei Monaten ab. Gerade einmal 11 Gedichte
              sind darin versammelt.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain align="right">
          <QuotePrimary author="Friedrich Hölderlin: Lied der Freundschaft">
            <span>Frei, wie Götter an dem Mahle,</span>
            <span>Singen wir um die Pokale,</span>
            <span>Wo der edle Trank erglüht,</span>
            <span>Voll von Schauern, ernst und stille,</span>
            <span>In des Dunkels heil'ger Hülle</span>
            <span>Singen wir der Freundschaft Lied. ...</span>
          </QuotePrimary>
        </Constrain>
      </Stack>

      <Stack>
        <Constrain>
          <Heading as="h2" level={3}>
            Drei Dichter – drei Handschriften
          </Heading>
        </Constrain>
        <ImageHotspots
          image={
            <Image
              attribution="WLB Stuttgart"
              image={data.bundesbuch01.childImageSharp.gatsbyImageData}
              alt="Titelei des Bundesbuchs mit handschriftlichen Namenseinträgen"
            />
          }
        >
          <ImageHotspot x={85} y={75}>
            <Constrain>
              <Stack>
                <Heading as="h1" level={3}>
                  Der heutige Aufbewahrungsort
                </Heading>
                <Paragraph>
                  Wie die meisten Originale von Hölderlin wird auch das
                  Bundesbuch im Hölderlin-Archiv der Württembergischen
                  Landesbibliothek, ehemals Königliche Landesbibliothek,
                  Stuttgart aufbewahrt.
                </Paragraph>
              </Stack>
            </Constrain>
          </ImageHotspot>
        </ImageHotspots>
        <Constrain>
          <Stack>
            <Paragraph>
              In der Titelei des Buches haben die drei Aldermänner eigenhändig
              ihre Namen eingetragen. So lässt sich auch auf den darauffolgenden
              Seiten leicht identifizieren, aus welcher Hand welches Gedicht
              stammt.
            </Paragraph>
            <Stack space={6}>
              <Columns alignY="center">
                <Column width="content">
                  <Box borderRadius="full" overflow="hidden">
                    <Image
                      alt="Porträt von Rudolf Friedrich Heinrich Magenau"
                      image={data.magenau.childImageSharp.gatsbyImageData}
                      sx={{ verticalAlign: "bottom" }}
                    />
                  </Box>
                </Column>
                <Column>
                  <Text size={[3, 3, 4]}>
                    Rudolf Friedrich Heinrich Magenau, <br />
                    aus Marck-Gröningen.
                  </Text>
                </Column>
              </Columns>
              <Columns alignY="center">
                <Column width="content">
                  <Box borderRadius="full" overflow="hidden">
                    <Image
                      alt="Porträt von Christian Ludwig Neuffer"
                      image={data.neuffer.childImageSharp.gatsbyImageData}
                      sx={{ verticalAlign: "bottom" }}
                    />
                  </Box>
                </Column>
                <Column>
                  <Text size={[3, 3, 4]}>
                    Christian Ludwig Neuffer, <br />
                    von Stuttgardt.
                  </Text>
                </Column>
              </Columns>
              <Columns alignY="center">
                <Column width="content">
                  <Box borderRadius="full" overflow="hidden">
                    <Image
                      alt="Porträt von Johann Christian Friedrich Hölderlin"
                      image={data.hoelderlin.childImageSharp.gatsbyImageData}
                      sx={{ verticalAlign: "bottom" }}
                    />
                  </Box>
                </Column>
                <Column>
                  <Text size={[3, 3, 4]}>
                    Johann Christian Friedrich Hölderlin, <br />
                    aus Lauffen.
                  </Text>
                </Column>
              </Columns>
            </Stack>
          </Stack>
        </Constrain>
      </Stack>

      <Stack>
        <Constrain>
          <Heading as="h3" level={3}>
            Magenaus ›Bundes-Lied‹
          </Heading>
        </Constrain>
        <Image
          image={data.bundesbuch02.childImageSharp.gatsbyImageData}
          alt="Aufgeschlagene Seite mit Magenaus ›Bundes-Lied‹ auf der rechten Seite"
        />
        <Constrain>
          <Stack>
            <Paragraph>
              Rudolf Magenau, der älteste im Bunde, war der Erste, der am 9.
              März 1790, dem »Tage der Einweihung«, sein ›Bundes-Lied‹ in das
              Buch eintrug.
            </Paragraph>
          </Stack>
        </Constrain>
      </Stack>

      <Stack>
        <Constrain>
          <Heading as="h3" level={3}>
            Neuffers ›Rundgesang für Freunde‹
          </Heading>
        </Constrain>
        <Image
          image={data.bundesbuch03.childImageSharp.gatsbyImageData}
          alt="Aufgeschlagene Buchseiten. Auf der rechten Seite beginnt Neuffers ›Rundgesang für Freunde‹"
        />
        <Constrain>
          <Stack>
            <Paragraph>
              Als Zweiter schrieb Christian Ludwig Neuffer seinen ›Rundgesang
              für Freunde‹ hinein.
            </Paragraph>
          </Stack>
        </Constrain>
      </Stack>

      <Stack>
        <Constrain>
          <Heading as="h3" level={3}>
            Hölderlins ›Lied der Freundschaft‹
          </Heading>
        </Constrain>
        <Image
          image={data.bundesbuch04.childImageSharp.gatsbyImageData}
          alt="Zwei Seiten zeigen die ersten Strophen von Hölderlins ›Lied der Freundschaft‹"
        />
        <Constrain>
          <Stack>
            <Paragraph>
              Hölderlin, den seine Freunde »Holz« nannten, verfasste zum
              gleichen Thema sein ›Lied der Freundschaft‹.
            </Paragraph>

            <ReadMoreBox cta="weiterlesen">
              <Heading as="span" level={5}>
                Lied der Freundschaft
              </Heading>
              <Poem size={[3, 3, 4]}>
                <span>Frei, wie Götter an dem Mahle,</span>
                <span>Singen wir um die Pokale,</span>
                <span>Wo der edle Trank erglüht,</span>
                <span>Voll von Schauern, ernst und stille,</span>
                <span>In des Dunkels heil'ger Hülle</span>
                <span>Singen wir der Freundschaft Lied.</span>
                <span>
                  <br />
                </span>
                <span>Schwebt herab aus kühlen Lüften,</span>
                <span>Schwebet aus den Schlummergrüften,</span>
                <span>Helden der Vergangenheit!</span>
                <span>Kommt in unsern Kreis hernieder,</span>
                <span>Staunt und sprecht: da ist sie wieder</span>
                <span>Unsre deutsche Herzlichkeit.</span>
                <span>
                  <br />
                </span>
                <span>Singe von ihr Jubellieder,</span>
                <span>Von der Wonne deutscher Brüder,</span>
                <span>Chronos! in dem ew'gen Lauf;</span>
                <span>Singe, Sohn der Afterzeiten!</span>
                <span>Sing’: Elysens Herrlichkeiten</span>
                <span>Wog ein deutscher Handschlag auf.</span>
                <span>
                  <br />
                </span>
                <span>Ha! der hohen Götterstunden!</span>
                <span>Wann der Edle sich gefunden,</span>
                <span>Der für unser Herz gehört;</span>
                <span>So begeisternd zu den Höhen,</span>
                <span>Die um uns, wie Riesen, stehen!</span>
                <span>So des deutschen Jünglings werth!</span>
                <span>
                  <br />
                </span>
                <span>Froher schlägt das Herz, und freier!</span>
                <span>Reichet zu des Bundes Feier</span>
                <span>Uns der Freund den Becher dar;</span>
                <span>Ohne Freuden, ohne Leben</span>
                <span>Erndtet' er Lyäus Reben</span>
                <span>Als er ohne Freunde war.</span>
                <span>
                  <br />
                </span>
                <span>Stärke, wenn Verläumder schreien</span>
                <span>Wahrheit, wenn Despoten dräuen</span>
                <span>Männermuth im Misgeschik,</span>
                <span>Duldung, wenn die Schwachen sinken,</span>
                <span>Liebe, Duldung, Wärme trinken</span>
                <span>Freunde von des Freundes Blik.</span>
                <span>
                  <br />
                </span>
                <span>Sanfter atmen Frühlingslüfte,</span>
                <span>Süßer sind der Linde Düfte,</span>
                <span>Küliger der Eichenhain,</span>
                <span>Wenn bekränzt mit jungen Rosen</span>
                <span>Freunde bei den Bechern kosen</span>
                <span>Freunde sich des Abends freu’n.</span>
                <span>
                  <br />
                </span>
                <span>Brüder! laßt die Thoren sinnen,</span>
                <span>Wie sie Fürstengunst gewinnen,</span>
                <span>Häufen mögen Gut und Gold;</span>
                <span>Lächelnd kans der Edle missen,</span>
                <span>Sich geliebt, geliebt zu wissen</span>
                <span>Diß ist seiner Thaten Sold.</span>
                <span>
                  <br />
                </span>
                <span>Schmettert aus der trauten Halle</span>
                <span>Auch die Auserwälten alle</span>
                <span>In die Ferne das Geschik;</span>
                <span>Wandelt er mit Schmerz beladen</span>
                <span>Nun auf freundelosen Pfaden</span>
                <span>Schwarzen Gram im bangen Blik;</span>
                <span>
                  <br />
                </span>
                <span>Wankt er, wenn sich Wolken türmen,</span>
                <span>Wankt er nun in Winterstürmen</span>
                <span>Ohne Leiter, ohne Stab;</span>
                <span>Lauscht er abgeblaicht und düster</span>
                <span>Bangem Mitternachtsgeflüster</span>
                <span>Ahndungsvoll am frischen Grab;</span>
                <span>
                  <br />
                </span>
                <span>O da kehren all’ die Stunden,</span>
                <span>So in Freundesarm verschwunden,</span>
                <span>Unter Schwüren, wahr, und warm,</span>
                <span>All' umfaßt mit sanftem Sehnen</span>
                <span>Seine Seele, süße Tränen</span>
                <span>Schaffen Ruhe nach dem Harm.</span>
                <span>
                  <br />
                </span>
                <span>Rauscht ihm dann des Todes Flügel;</span>
                <span>Schläft er ruhig unter’m Hügel,</span>
                <span>Wo sein Bund den Kranz ihm flicht;</span>
                <span>In die Loken seiner Brüder</span>
                <span>Säuselt noch sein Geist hernieder,</span>
                <span>Lispelt leis: Vergeßt mich nicht!</span>
              </Poem>
            </ReadMoreBox>
            <Paragraph>
              Es folgten ein ›Lied der Liebe‹ und Verse ›An die Stille‹. Allen
              dreien ist bereits jener hymnische Ton zu eigen, der Hölderlins
              Tübinger Dichtung im Ganzen kennzeichnet.
            </Paragraph>
          </Stack>
        </Constrain>
      </Stack>

      <Stack>
        <Constrain>
          <Heading as="h2" level={3}>
            Ein Dichter im Schlafrock
          </Heading>
        </Constrain>
        <Image
          image={data.schlafrock.childImageSharp.gatsbyImageData}
          alt="Eine bunte Zeichnung zeigt einen Dichter im Schlafrock am Schreibpult vor einer Bücherwand"
          caption="Der Stiftler im Schlafrock. Zeichnung aus der Württembergischen Landesbibiliothek Stuttgart"
        />
        <Constrain>
          <Stack>
            <Paragraph>
              Mit dem Bundesbuch wurde auch ein Brief von Magenau an den krank
              zuhause weilenden Neuffer überliefert, der eine kleine Karikatur
              enthält: Sie zeigt den dichtenden Hölderlin in Neuffers geblümtem
              Schlafrock. Dazu berichtet Magenau von Hölderlins nächtlichen
              Aktivitäten im ›Ochsenstall‹, dem gemeinsam geteilten Schlafsaal
              im Stift:
            </Paragraph>
            <Blockquote as="blockquote">
              <Paragraph as="span">
                »Nur hie u(nd) da erschallt der Ochsenstall von Holzens
                Cenaurähnlichem Poeten Schritt, wenn allen fals aufs Wörtchen:
                Fluchtal : der schwere Reim ihm noch gebricht. Auch sieht ihn
                oft der welke Wöhrd [gemeint ist das Neckarufer] in deinem
                Schlafrok durch des hohen Stalles nidre Fenster Pforte bliken,
                gen Himmel schaut er, ob ihm nicht des Gottes Salbung möchte
                hernider fließen.«
              </Paragraph>
            </Blockquote>
          </Stack>
        </Constrain>
        <Constrain>
          <Paragraph>
            Im Herbst 1790 bezog Hölderlin mit dem gleichaltrigen Georg Wilhelm
            Friedrich Hegel, dem gerade einmal 15-jährigen Friedrich Wilhelm
            Joseph Schelling und sieben weiteren Stipendiaten eine der engen
            beheizbaren Winterstuben. Mehr zur Freundschaft der drei großen
            Denker und ihrer legendären Philosophen-WG{" "}
            <TextLink to="/sonderausstellungen/hegel-hoelderlin/">
              gibt es hier
            </TextLink>
            .
          </Paragraph>
        </Constrain>
      </Stack>
    </Stack>
  )
}

export default Bundesbuch
